import axios from "axios";
import { gettoken } from "../token/token";
import apiurl from "../../../admin/shared/services/apiendpoint/apiendpoint";

export const apigetallbooksdata = async (params) => {
    try {
        const token = gettoken();
        const res = await axios.get(`${apiurl()}/books/apigetbooksforswiper`,{params:params,headers: { "Authorization": `Bearer ${token}` }});
        return res.data;
    } catch (err) {
        console.log(err);
    }
};


export const getProductById = async (productId) => {
    try {
        const response = await fetch(`${apiurl()}/books/apigetbookbyID/${productId}`);
        if (!response.ok) {
            throw new Error('Product not found');
        }
        const data = await response.json();
        return data.resdata; 
    } catch (error) {
        console.error('Error fetching product:', error);
        throw error;
    }
};