/* eslint-disable react/prop-types */
import { Dialog } from "primereact/dialog";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { AutoComplete } from "primereact/autocomplete";
import { useCallback, useEffect, useState } from "react";
import { apigetallbooksnames } from "../../services/apipurchase/apipurchase";

export default function Addandeditform({ visible, setVisible, loading, Formfunction, handlesave, handleupdate, formdata, handlechange, handlechangeProduct, addRow, handledeleteField }) {

    const [bookSuggestions, setBookSuggestions] = useState([]);
    const [allBooks, setAllBooks] = useState([]);
    let isMounted = true;

    const fetchBooks = useCallback(async() =>{
        try {
            const response = await apigetallbooksnames();
            if (response && response.resdata) {
                setAllBooks(response.resdata.map(book => book.Book_Name));
            }
        } catch (error) {
            console.error('Error fetching books:', error);
        }
    },[])

    useEffect(() => {
        if (isMounted) {
            fetchBooks();
        }
        return () => (isMounted = false);
      }, [fetchBooks]);

    const searchBooks = (event) => {
        const query = event.query.toLowerCase();
        const suggestions = allBooks.filter(book => 
            book.toLowerCase().includes(query)
        );
        setBookSuggestions(suggestions);
    };

    const sno = (rowData, rowIndex) => {
        return (
            <div>
                {rowIndex.rowIndex + 1}
            </div>
        );
    };


    const Title = (rowData, rowIndex) => {
        return (
            <div className="w-full">
                <AutoComplete
                    value={rowData?.Book_Name || ''}
                    suggestions={bookSuggestions}
                    completeMethod={searchBooks}
                    onChange={(e) => handlechangeProduct({
                        target: { name: 'Book_Name', value: e.value}
                    }, rowIndex)}
                    className="w-full"
                    inputClassName="w-full px-4 py-2 border rounded-md outline-none"
                    required
                />
            </div>
        );
    };

    const ISBN = (rowData, rowIndex) => {
        return (
            <div>
                <input type="text" name="ISBNnumber" value={rowData?.ISBNnumber || ''} onChange={(event) => handlechangeProduct(event, rowIndex)} className="w-full px-4 py-2 border rounded-md outline-none" required />
            </div>
        )
    }


    const Quantity = (rowData, rowIndex) => {
        return (
            <div>
                <input type="text" name="Quantity" value={rowData?.Quantity || ''} onChange={(event) => handlechangeProduct(event, rowIndex)} className="w-full px-4 py-2 border rounded-md outline-none" required />
            </div>
        )
    }

    const UnitPrice = (rowData, rowIndex) => {
        return (
            <div>
                <input type="text" name="UnitPrice" value={rowData?.UnitPrice || ''} onChange={(event) => handlechangeProduct(event, rowIndex)} className="w-full px-4 py-2 border rounded-md outline-none" required />
            </div>
        )
    }

    const Discount = (rowData, rowIndex) => {
        return (
            <div>
                <input type="text" name="Discount" value={rowData?.Discount || ''} onChange={(event) => handlechangeProduct(event, rowIndex)} className="w-full px-4 py-2 border rounded-md outline-none" required />
            </div>
        )
    }
    const TotalPrice = (rowData, rowIndex) => {
        return (
            <div>
                <input type="text" name="TotalPrice" value={rowData?.TotalPrice || ''} onChange={(event) => handlechangeProduct(event, rowIndex)} className="w-full px-4 py-2 border rounded-md outline-none" required />
            </div>
        )
    }


    const actions = (rowData, rowIndex) => {
        return (
            <div className="flex gap-2">
                <button
                    type="button"
                    onClick={(e) => handledeleteField(e, rowIndex)}
                    className="p-2 text-red-600 hover:text-red-800"
                    title="Delete"
                    disabled={formdata.products.length <= 1}
                >
                    <i className="fi fi-rr-trash"></i>
                </button>
            </div>
        );
    };

    return (
        <Dialog className='relative z-[1] w-full h-full' header="Order Form" visible={visible} maximizable onHide={() => setVisible(false)}>
            <form onSubmit={Formfunction == "Save" ? handlesave : handleupdate}>
                <div className="grid grid-cols-1 gap-2 md:grid-cols-12">
                    <div className='col-span-10'>
                        <div className="grid items-end grid-cols-1 gap-2 md:grid-cols-6">
                            <div>
                                <label className="block mb-2 text-sm font-medium dark:text-white">First Name</label>
                                <input type="text" name="First_Name" value={formdata?.First_Name} onChange={(e) => handlechange(e)} className="w-full px-4 py-2 border rounded-md outline-none" required />
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium dark:text-white">Last Name</label>
                                <input type="text" name="Last_Name" value={formdata?.Last_Name} onChange={(e) => handlechange(e)} className="w-full px-4 py-2 border rounded-md outline-none" />
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium dark:text-white">Mobile Number</label>
                                <input type="number" name="Mobilenumber" value={formdata?.Mobilenumber} onChange={(e) => handlechange(e)} className="w-full px-4 py-2 border rounded-md outline-none" required />
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium dark:text-white">Address</label>
                                <input type="text" name="Address" value={formdata?.Address} onChange={(e) => handlechange(e)} className="w-full px-4 py-2 border rounded-md outline-none" required />
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium dark:text-white">City</label>
                                <input type="text" name="City" value={formdata?.City} onChange={(e) => handlechange(e)} className="w-full px-4 py-2 border rounded-md outline-none" required />
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium dark:text-white">State</label>
                                <input type="text" name="State" value={formdata?.State} onChange={(e) => handlechange(e)} className="w-full px-4 py-2 border rounded-md outline-none" required />
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium dark:text-white">Country</label>
                                <input type="text" name="Country" value={formdata?.Country} onChange={(e) => handlechange(e)} className="w-full px-4 py-2 border rounded-md outline-none" required />
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium dark:text-white">Pincode</label>
                                <input type="text" name="Zipcode" value={formdata?.Zipcode} onChange={(e) => handlechange(e)} className="w-full px-4 py-2 border rounded-md outline-none" required />
                            </div>
                        </div>
                    </div>

                    <div className='justify-end col-span-2 md:flex'>
                        <div>
                            <div>
                                <label className="block mb-2 text-sm font-medium dark:text-white">Order Date</label>
                                <input type="date" name="Order_Date" value={formdata?.Order_Date} onChange={(e) => handlechange(e)} className="w-full px-4 py-2 border rounded-md outline-none" required />
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div>
                        <DataTable value={formdata?.products} className='my-5'>
                            <Column header="S.No" body={sno} style={{ minWidth: '50px' }} />
                            <Column header="Book Title" body={Title} style={{ minWidth: '200px' }} />
                            <Column header="ISBN" body={ISBN} style={{ minWidth: '150px' }} />
                            <Column header="Quantity" body={Quantity} style={{ minWidth: '95px' }} />
                            <Column header="Unit Price" body={UnitPrice} style={{ minWidth: '95px' }} />
                            <Column header="Discount (%)" body={Discount} style={{ minWidth: '95px' }} />
                            <Column header="Total Price" body={TotalPrice} style={{ minWidth: '95px' }} />

                            <Column header="Actions" body={actions} style={{ minWidth: '100px' }} />
                        </DataTable>
                        <div className="flex justify-between mt-3">
                        <button 
                            type="button" 
                            onClick={addRow}
                            className="px-4 py-2 text-white bg-blue-500 rounded-md"
                        >
                            <i className="fi fi-rr-plus"></i> Add Row
                        </button>
                        <div>QTY: {formdata?.Total_Quantity || 0}</div>
                        <div>Total: {formdata?.Total_Amount || 0}</div>
                    </div>

                    </div>
                </div>

                <div className="flex justify-center mt-2">
                    <button type="submit" className="py-2 px-4 rounded-md border w-[100px] bg-[#193762] text-white" >
                        {loading && <span className="animate-spin text-xl inline-block size-4 border-[3px] border-current border-t-transparent text-white rounded-full" role="status" aria-label="loading"></span>}  {Formfunction}
                    </button>
                </div>
            </form>
        </Dialog>
    )
}