/* eslint-disable no-unused-vars */
import Approuter from "./Router/Approuter"
import $ from 'jquery';
import './assets/vendors/@fontsource/fira-sans/index.css'
import './assets/vendors/@fontsource/montserrat/index.css'

import './assets/vendors/smooth-scroll/dist/smooth-scroll.min.js'
import './assets/vendors/jquery/dist/jquery.min.js'
import './assets/vendors/preline/dist/preline.js'


import { HSStaticMethods } from "preline";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
const queryClient = new QueryClient(
  {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  }
);

window.jQuery = $;
window.$ = $;
HSStaticMethods.autoInit();
const observer = new MutationObserver((mutationsList) => {
    for (const mutation of mutationsList) {
      HSStaticMethods.autoInit();
    }
});

observer.observe(document.body, {
    attributes: true,
    subtree: true,
    childList: true,
    characterData: true,
});

function App() {


  return (
    <>
     <QueryClientProvider client={queryClient}>
      <Approuter />
      </QueryClientProvider>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  )
}

export default App
