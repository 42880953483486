import axios from "axios";
import { gettoken } from "../token/token";
import apiurl from "../apiendpoint/apiendpoint";

export const apiSaveShipping = async (formdata) => {
    try {
        const token = gettoken();
        const res = await axios.post(`${apiurl()}/customers/apisaveshippingAddress`, formdata, { headers: { "Authorization": `Bearer ${token}`, "Content-Type": "application/json" }});

        return res.data;
    } catch (err) {
        console.log(err);
    }
};


export const apiupdateShippingAddress = async ({ _id, Othersdata }) => {
    try {
        const token = gettoken();
        const res = await axios.put(`${apiurl()}/customers/updateShippingaddress`,Othersdata ,{params:{_id}, headers: { "Authorization": `Bearer ${token}` }});
        return res.data;
    } catch (err) {
        console.log(err);
    }
};

export const apideleteShippingAddress = async (_id) => {
    try {
        const token = gettoken();
        const res = await axios.delete(`${apiurl()}/customers/apideleteShipping`, {params: { _id },headers: { "Authorization": `Bearer ${token}` }  });
        return res.data;
    } catch (err) {
        console.error("API Delete Error:", err);
    }
};

