import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { apisendotp } from "../../services/apiauthentication/apiregister";
import toast from "react-hot-toast";


export default function VerifyOtp() {
  const [OTP, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const email = new URLSearchParams(location.search).get("email"); 

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await apisendotp({ Email: email, OTP });
      if (response.message === "OTP verified successfully") {
        toast.success("OTP Verified. Registration Complete!");
        navigate("/login"); 
      } else {
        toast.error(response.message); 
      }
    } catch (error) {
      console.log("Error verifying OTP:", error);
    }
    setLoading(false);
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="max-w-[30rem] mx-auto w-full px-4">
        <div className="p-8 bg-white border shadow-xl rounded-xl">
          <h1 className="mb-6 text-2xl font-bold text-center">Verify OTP</h1>
          <form onSubmit={handleOtpSubmit}>
            <div className="mb-4">
              <label className="block text-lg">OTP</label>
              <input
                type="text"
                name="otp"
                value={OTP}
                onChange={handleOtpChange}
                className="w-full px-4 py-2 border outline-none rounded-xl"
                required
              />
            </div>
            <div className="flex items-center justify-center">
              <button
                type="submit"
                className="inline-flex items-center justify-center px-6 py-3 text-xl font-bold text-white rounded-lg bg-regal-blue"
                disabled={loading}
              >
                {loading ? "Verifying..." : "Verify OTP"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
