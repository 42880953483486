import axios from "axios";
import { gettoken } from "../token/token";
import apiurl from "../../../admin/shared/services/apiendpoint/apiendpoint";

export const apigetallclassesdesign = async (params) => {
    try {
        const token = gettoken();
        const res = await axios.get(`${apiurl()}/classes/apigetclassforswiper`,{params:params,headers: { "Authorization": `Bearer ${token}` }});
        return res.data;
    } catch (err) {
        console.log(err);
    }
};
