import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import apiurl from "../../services/apiendpoint/apiendpoint";
import useCartStore from "../../services/store/usecart";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { isLoggedIn } from "../../services/token/token";
import { Link, useNavigate } from "react-router-dom";
import CheckoutSidebar from "./Checkoutsidebar";
import { useCallback, useEffect, useState } from "react";
import useAuth from "../../services/store/useAuth";
import { getallcart, handleClearCart, handleDecreaseQuantity, handleIncreaseQuantity, handleRemoveItem } from "./CartFunctions";

/* eslint-disable react/prop-types */
export default function Cartsidebar({ isOpen, onClose }) {
    const [isCheckoutSidebarOpen, setIsCheckoutSidebarOpen] = useState(false);
    const { userdetails } = useAuth();
    let isMounted = true;
    const navigate = useNavigate();
    const { cart, setCartItems, removeFromCart, clearCart } = useCartStore();
    const safeCart = Array.isArray(cart) ? cart : [];

    const gotocheckout = () => {
        if (isLoggedIn()) {
            onClose();
            setTimeout(() => {
                setIsCheckoutSidebarOpen(true);
            }, 300);
        } else {
            onClose();
            setIsCheckoutSidebarOpen(false);
            navigate('/login', { state: { status: 'checkoutlogin' } });
        }
    };

    const getAllCartItems = useCallback(() => {
        getallcart(userdetails, cart, setCartItems);
    }, [cart, userdetails?.Email]);

    useEffect(() => {
        if (isMounted) {
            getAllCartItems();
        }
        return () => (isMounted = false);
    }, [cart]);

    const calculateTotals = () => {
        return {
            totalQuantity: safeCart.reduce((total, item) => total + (Number(item?.Quantity) || 0), 0),
            subTotal: safeCart.reduce((total, item) =>
                total + ((Number(item?.Quantity) || 0) * (Number(item?.productId?.Sale_Price) || 0)), 0)
        };
    };

    const { totalQuantity, subTotal } = calculateTotals();

    return (
        <>
            <div className={`fixed top-0 right-0 lg:w-[25%] md:w-[50%] xl:w-[40%] 2xl:w-[30%] w-full h-full bg-white shadow-lg z-50 transform ${isOpen ? "translate-x-0" : "translate-x-full"
                } transition-transform duration-300 ease-in-out`}>
                <div className="p-4">
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 fill-black" viewBox="0 0 320.591 320.591">
                            <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z" data-original="#000000"></path>
                            <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z" data-original="#000000"></path>
                        </svg>
                    </button>
                    <h2 className="mt-4 text-lg font-semibold">Your Cart</h2>
                    {safeCart.length > 0 ? (
                        <div className="p-4 space-y-4 overflow-y-auto">
                            {safeCart.map((item, index) => (
                                <div key={item._id} className="flex items-center pb-4 space-x-4 border-b">
                                    <img src={`${apiurl()}/${item?.productId?.Book_image?.[0] || 'default_image_path'}`} alt={item?.Book_Name || 'Default Book Name'} className="object-cover w-16 h-16 rounded-md" />

                                    <div className="flex-1">
                                        <h3 className="font-semibold">{item.productId?.Book_Name}</h3>
                                        <div className="flex items-center mt-2">
                                            <button className="px-2 py-1 text-gray-800 bg-gray-300 rounded" onClick={() => handleDecreaseQuantity(index, safeCart, userdetails, setCartItems)} disabled={item.quantity <= 1} >
                                                -
                                            </button>
                                            <span className="mx-2">{item.Quantity}</span>
                                            <button className="px-2 py-1 text-gray-800 bg-gray-300 rounded" onClick={() => handleIncreaseQuantity(index, safeCart, userdetails, setCartItems)} >
                                                +
                                            </button>
                                        </div>
                                    </div>
                                    <FontAwesomeIcon icon={faTrash} className="text-red-600 cursor-pointer" onClick={() => handleRemoveItem(item._id, removeFromCart)} />
                                </div>
                            ))}
                            <div className="space-y-2 text-lg font-semibold">
                                <h1>Total Quantity : {totalQuantity}</h1>
                                <h1>Sub Total : ₹ {subTotal.toFixed(2)}</h1>
                            </div>
                            <div className="grid grid-cols-1 gap-5 lg:grid-cols-2">
                                <button className="w-full px-4 py-2 mt-4 text-white rounded bg-regal-blue" onClick={() => handleClearCart(userdetails, clearCart)}> Clear Cart </button>
                                <button className="w-full px-4 py-2 mt-4 text-white rounded bg-regal-yellow" onClick={gotocheckout}>Proceed to Checkout</button>
                            </div>
                        </div>
                    ) : (
                        <div className="p-4 text-center">
                        <img src="/images/No_Product_Found.png" alt="" />
                        <h1 className="text-xl font-semibold text-center text-[#292764]">No Product found in your Cart.</h1>
                        <Link to="/study-materials">
                            <button onClick={onClose} className="p-2 mt-5 text-lg text-white rounded-lg bg-regal-blue hover:bg-gradient-to-l hover:from-regal-yellow hover:to-regal-blue">Continue Shopping</button>
                        </Link>
                    </div>
                    )}
                </div>
            </div>
            <CheckoutSidebar isOpen={isCheckoutSidebarOpen} onClose={() => setIsCheckoutSidebarOpen(false)} />
        </>
    );
}
