/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apigetallstuddashcourse } from "../../../services/apistudentdashboard/apistudentDash";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import apiurl from "../../../services/apiendpoint/apiendpoint";
import { gettoken } from "../../../../../shared/services/token/token";
import axios from "axios";

export default function CourseDetails() {
  const { courseId } = useParams();
  const [courses, setCourses] = useState([]);
  const [activeTab, setActiveTab] = useState("pdf");
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [embedUrl, setEmbedUrl] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    toolbarPlugin: {
      zoomPlugin: true,
      pageNavigationPlugin: true,
    },
    renderToolbar: (Toolbar) => {},
  });

  useEffect(() => {
    const disableTextSelection = () => {
      document.addEventListener("selectstart", (e) => e.preventDefault());
    };

    const disableRightClick = () => {
      document.addEventListener("contextmenu", (e) => e.preventDefault());
    };

    const disableCopyPaste = (e) => {
      if (e.ctrlKey && (e.key === "c" || e.key === "p")) {
        e.preventDefault();
      }
    };

    disableTextSelection();
    disableRightClick();
    document.addEventListener("keydown", disableCopyPaste);

    return () => {
      document.removeEventListener("selectstart", (e) => e.preventDefault());
      document.removeEventListener("contextmenu", (e) => e.preventDefault());
      document.removeEventListener("keydown", disableCopyPaste);
    };
  }, []);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const params = {};
        const data = await apigetallstuddashcourse(params);
        setCourses(data.coursesData);
        const course = data.coursesData.find(
          (course) => course._id === courseId
        );
        setSelectedCourse(course);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchCourses();
  }, [courseId]);

  useEffect(() => {
    if (selectedCourse && selectedCourse.Course_Video) {
      const youtubeId = extractYouTubeId(selectedCourse.Course_Video);
      if (youtubeId) {
        setEmbedUrl(`https://www.youtube.com/embed/${youtubeId}`);
      } else {
        setEmbedUrl(selectedCourse.Course_Video);
      }
    }
  }, [selectedCourse]);

  useEffect(() => {
    const fetchPdfWithToken = async () => {
      try {
        setLoading(true);
        setError(null);

        const token = gettoken();
        const response = await axios.get(
          `${apiurl()}/stream/apigetcoursepdf?_id=${selectedCourse._id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            responseType: "blob",
          }
        );

        const pdfBlobUrl = URL.createObjectURL(response.data);
        setPdfUrl(pdfBlobUrl);
      } catch (error) {
        setError("Failed to load PDF. Please try again.");
        console.error("Error fetching PDF:", error);
      } finally {
        setLoading(false);
      }
    };

    if (selectedCourse && selectedCourse._id) {
      fetchPdfWithToken();
    }
  }, [selectedCourse]);

  const extractYouTubeId = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  };

  if (!selectedCourse) return <div>Loading...</div>;

  return (
    <div className="px-4 py-8">
      <h2 className="mb-6 text-2xl font-bold">{selectedCourse.Course_Title}</h2>
      <div className="mb-4 space-x-5">
        <button
          className={`inline-flex items-center justify-center px-6 py-3 text-xl font-bold leading-6 text-center ${
            activeTab === "pdf"
              ? "text-white transition-all duration-300 ease-in-out rounded-lg gap-x-3 bg-regal-blue hover:bg-gradient-to-l hover:from-regal-yellow hover:to-regal-blue"
              : "text-blue-600 border-b-2 border-blue-600"
          }`}
          onClick={() => setActiveTab("pdf")}
        >
          View Pdf
        </button>
        <button
          className={`inline-flex items-center justify-center px-6 py-3 text-xl font-bold leading-6 text-center ${
            activeTab === "video"
              ? "text-white transition-all duration-300 ease-in-out rounded-lg gap-x-3 bg-regal-blue hover:bg-gradient-to-l hover:from-regal-yellow hover:to-regal-blue"
              : "text-blue-600 border-b-2 border-blue-600"
          }`}
          onClick={() => setActiveTab("video")}
        >
          View Video
        </button>
      </div>
      <div className="p-6 bg-white rounded-lg shadow-md">
        {activeTab === "pdf" ? (
          <div className="w-full h-[75vh] md:h-[85vh]">
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
              {loading ? (
                "Loading..."
              ) : error ? (
                <div>{error}</div>
              ) : (
                <Viewer fileUrl={pdfUrl} plugins={[defaultLayoutPluginInstance]}
                />
              )}
            </Worker>
          </div>
        ) : (
          <div className="w-full">
            {embedUrl &&
              (embedUrl.includes("youtube.com") ? (
                <iframe className="w-full h-[40vh] md:h-[60vh]" src={embedUrl} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="Video player"
                />
              ) : (
                <video controls className="w-full h-[40vh] md:h-[60vh]" src={embedUrl}
                >
                  Your browser does not support the video tag.
                </video>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}
