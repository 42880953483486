import axios from "axios";
import { gettoken } from "../token/token";
import apiurl from "../../../admin/shared/services/apiendpoint/apiendpoint";

export const apisaveStudApplication = async (datas) => {
    try {
        const formData = new FormData();
        for (const key in datas) {
            formData.append(key, datas[key]);
        }
        const token = gettoken();
        const res = await axios.post(`${apiurl()}/application/apisaveStudapplication`, formData, { headers: { "Authorization": `Bearer ${token}` }});

        return res.data;
    } catch (err) {
        console.log(err);
    }
};

export const apiBulkSaveStudApplication = async (applications) => {
    try {
        const token = gettoken();
        
        // Format the request body correctly
        const requestBody = {
            applications: applications.map(app => ({
                ...app,
                CreatedDate: new Date().toISOString(),
                Status: "Pending",
                IsActive: true
            }))
        };

        const res = await axios.post(
            `${apiurl()}/application/saveBulkStudentApplications`,
            requestBody,
            {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            }
        );

        return res.data;
    } catch (err) {
        console.error("Bulk upload error:", err);
        throw err;
    }
};
