import { useQuery, useQueryClient } from "@tanstack/react-query";
import DataTable from "../shared/components/Applications/DataTable";
import TableheadPanel from "../shared/components/Applications/Tableheadpanel";
import { apideletestudapplication, apigetallapplications, apiupdatestudapplication, updateStatus } from "../shared/services/apiapplicationreceived/apireceivedapplications";
import useTable from "../hooks/useTable";
import Cuspagination from "../shared/components/Courses/Cuspagination";
import toast from "react-hot-toast";
import Addandeditform from "../shared/components/Applications/Addandeditform";
import useForm from "../hooks/useForm";
import { apisaveStudApplication } from "../../shared/services/apistudapplication/apistudApplication";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import BulkUpload from "../shared/components/Applications/BulkUpload";

export default function Applicationrecevpage() {
    const { page, first, rows, globalFilter, setGlobalFilter, onPage } = useTable();
    const { formdata, handlechange, visible, setVisible, openform, resetForm, editform, openBulkModal,visibleBulkModal,setVisibleBulkModal } = useForm({});

    const queryClient = useQueryClient();

    const handlesave = async (e) => {
        e.preventDefault();
        const res = await apisaveStudApplication(formdata);
        console.log(res);
        res.message == "Successfully saved" ? handlesuccess(res) : toast.error(res.message);
    }

    const { data } = useQuery({
        queryKey: ['studentdata', page, rows, globalFilter],
        queryFn: () => apigetallapplications({ first, rows, globalFilter }),
        keepPreviousData: true
    });

    const handleStatusChange = async (id, newStatus) => {
        if (!id) {
            console.error("Invalid id:", id);
            toast.error("Invalid application ID");
            return;
        }

        queryClient.setQueryData(['studentdata', page, rows, globalFilter], oldData => {
            return {
                ...oldData,
                resdata: oldData.resdata.map(item =>
                    item._id === id ? { ...item, Application_Status: newStatus } : item
                ).filter(item => !(item._id === id && newStatus === "verified"))
            };
        });

        try {
            const res = await updateStatus(id, newStatus);
            console.log(res.message);
            toast.success("Status updated successfully!");
            queryClient.invalidateQueries(['studentdata', page, rows, globalFilter]);
        } catch (error) {
            console.error("Error updating status:", error);
            toast.error("Failed to update status.");
            queryClient.invalidateQueries(['studentdata', page, rows, globalFilter]);
        }
    };

    const handlesuccess = (res) => {
        toast.success(res.message)
        queryClient.invalidateQueries('studentdata');
        resetForm();
    }
    const handleupdate = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        
        for (const [key, value] of Object.entries(formdata)) {
            // Only append if value is not null/undefined
            if (value !== null && value !== undefined) {
                formData.append(key, value);
            }
        }
        
        if (formdata.ProfileImage instanceof File) {
            formData.append('ProfileImage', formdata.ProfileImage);
        }
        
        try {
            const res = await apiupdatestudapplication(formdata._id, formData);
            console.log("API Response:", res);
            res.message === "Successfully updated" ? handlesuccess(res) : toast.error(res.message);
        } catch (err) {
            console.error("Update Error:", err);
            toast.error("Failed to update application.");
        }
    };
    
    
    
    const handledelete = async (_id) => {
        const res = await apideletestudapplication(_id);
        res.message == "Successfully deleted" ? handlesuccess(res) : toast.error(res.message);
    }

    const confirm2 = (_id) => {
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger bg-red-500 ml-2 text-white py-2 px-3',
            rejectClassName: 'ml-2  py-2 px-3',
            accept: () => { handledelete(_id) }
        });
    };

    return (
        <>
            <TableheadPanel setGlobalFilter={setGlobalFilter} openform={openform} openBulkModal={openBulkModal} />
            <DataTable data={data} handleStatusChange={handleStatusChange} editform={editform} confirm2={confirm2} />
            {data?.totallength > 0 && (<Cuspagination page={page} rows={rows} onPage={onPage} totallength={data?.totallength} />)}
            <Addandeditform visible={visible} setVisible={setVisible} formdata={formdata} handlechange={handlechange} handlesave={handlesave} handleupdate={handleupdate} />
            <BulkUpload visibleBulkModal={visibleBulkModal} setVisibleBulkModal={setVisibleBulkModal} />
            <ConfirmDialog />
        </>
    );
}
