import toast from "react-hot-toast";
import useForm from "../hooks/useForm";
import Addanndeditform from "../shared/components/ongoingandupcomming/addandeditform";
import ClassTable from "../shared/components/ongoingandupcomming/classTable";
import Tableheadpanel from "../shared/components/ongoingandupcomming/Tableheadpanel";
import { apideleteclasses, apigetallclasses, apisaveclasses, apiupdateclasses } from "../shared/services/apiclass/apiclasses";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import useTable from "../hooks/useTable";
import Cuspagination from "../shared/components/Courses/Cuspagination";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'


export default function Onandupcomeclasspage () {

    const { page, first, rows, globalFilter, setGlobalFilter, onPage } = useTable();
    const { formdata, handlechange, visible, setVisible, openform, resetForm,editform } = useForm({});
    const queryClient = useQueryClient();
    const { data } = useQuery({ queryKey: ['classesData', page, rows, globalFilter], queryFn: () => apigetallclasses({ first, rows, globalFilter }) })

    const handlesave = async (e) => {
      e.preventDefault();
      console.log("Form data being saved:", formdata);
      const res = await apisaveclasses(formdata);
      console.log("API response:", res);
      res.message == "Successfully saved" ? handlesuccess(res) : toast.error(res.message);
    }

    const handleupdate = async (e) => {
        e.preventDefault();
        const { _id, ...Othersdata } = formdata
        const res = await apiupdateclasses(_id, Othersdata);
        res.message == "Successfully updated" ? handlesuccess(res) : toast.error(res.message);
    
      }
    
    const handlesuccess = (res) => {
        toast.success(res.message)
        queryClient.invalidateQueries('classesData');
        resetForm();
    }

    const confirm2 = (_id) => {
        confirmDialog({
          message: 'Do you want to delete this record?',
          header: 'Delete Confirmation',
          icon: 'pi pi-info-circle',
          defaultFocus: 'reject',
          acceptClassName: 'p-button-danger bg-red-500 ml-2 text-white py-2 px-3',
          rejectClassName: 'ml-2  py-2 px-3',
          accept: () => { handledelete(_id) }
        });
      };
      const handledelete = async (_id) => {
        const res = await apideleteclasses(_id);
        res.message == "Successfully deleted" ? handlesuccess(res) : toast.error(res.message);
      }

    return(
        <>
        <Tableheadpanel openform={openform} setGlobalFilter={setGlobalFilter} />
        <ClassTable data={data} editform={editform} confirm2={confirm2} />
        {data?.totallength > 0 && (<Cuspagination page={page} rows={rows} onPage={onPage} totallength={data?.totallength} />)}
        <Addanndeditform visible={visible} setVisible={setVisible} formdata={formdata}
                handlechange={handlechange} handlesave={handlesave} handleupdate={handleupdate}/>
        <ConfirmDialog />
        </>

    )
}