import axios from "axios";
import { gettoken } from "../../../../shared/services/token/token";
import apiurl from "../apiendpoint/apiendpoint";

export const apigetallapplications = async (params) => {
    try {
        const token = gettoken();
        const res = await axios.get(`${apiurl()}/application/apigetallstudentApplications`,{params:params,headers: { "Authorization": `Bearer ${token}` }});
        return res.data;
    } catch (err) {
        console.log(err);
    }
};

export const updateStatus = async (id, status) => {
    try {
        const token = gettoken();
        const res = await axios.put(`${apiurl()}/application/${id}/status`, { Application_Status: status }, { headers: { "Authorization": `Bearer ${token}` }});
        return res.data;
    } catch (error) {
        console.error("Error updating status:", error);
    }
};

export const apigetverifiedapplications = async (params) => {
    try {
        const token = gettoken();
        const res = await axios.get(`${apiurl()}/application/apigetverifiedapplications`,{params:params,headers: { "Authorization": `Bearer ${token}` }});
        return res.data;
    } catch (err) {
        console.log(err);
    }
};

export const apisendcredentials = async (params) => {
    try {
        const token = gettoken();
        const res = await axios.post(`${apiurl()}/application/sendcredentials`, params, {headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json"} });
        return res.data;
    } catch (err) {
        console.error('Error in API call:', err);
        throw err; 
    }
};

export const apiupdatestudapplication = async (_id, formData) => {
    try {
        const token = gettoken();
        const res = await axios.put(`${apiurl()}/application/apiupdatemanualapplication/${_id}`, formData, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "multipart/form-data"
            }
        });
        return res.data;
    } catch (err) {
        console.error(err);
    }
};

export const apiupdateaddedcourses = async (_id,datas) => {
    try {
        const token = gettoken();
        const res = await axios.put(`${apiurl()}/application/apiupdateaddedcourses/${_id}`, datas,{headers: { "Authorization": `Bearer ${token}` }});
        return res.data;
    } catch (err) {
        console.log(err);
    }
};


export const apideletestudapplication = async (_id) => {
    try {
        const token = gettoken();
        const res = await axios.delete(`${apiurl()}/application/apideletestudapplication/${_id}`,{headers: { "Authorization": `Bearer ${token}` }});
        return res.data;
    } catch (err) {
        console.log(err);
    }
};