import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "../core/Main/Main";
import HomePage from "../components/HomePage/Homepage";
import Aboutus from "../shared/components/Home/Aboutus";
import Ourgallery from "../shared/components/Home/Ourgallery";
import ApplicationformPage from "../components/ApplicationformPage/Applicationformpage";
import Studymaterials from "../shared/components/Home/StudyMaterials";
import Loginform from "../shared/components/Loginform/Loginform";
import ProtectedRoute from "../shared/services/token/ProtectedRoute";
import AdminRouter from "../admin/AdminRouter/Adminrouter";
import RegisterFormPage from "../components/RegisterFormPage/Registerformpage";
import VerifyOtp from "../shared/components/RegisterForm/veifyOtp";
import MyAccount from "../components/MyAccount/Myaccount";
import Myorders from "../components/MyOrders/Myorders";
import { ForgotPassword } from "../shared/components/Loginform/Forgotpassword";
import { VerifyForgotPasswordOTP } from "../shared/components/Loginform/VerifyForgotPasswordOTP";
import { ResetPassword } from "../shared/components/Loginform/ResetPassword";
import Viewproductfunction from "../components/ViewProductFunction/Viewproductfunction";
import TermsandConditions from "../shared/components/PrivacyRefundT&CPages/Termsandconditions";
import PrivacyPolicy from "../shared/components/PrivacyRefundT&CPages/PrivacyPolicy";
import Refundpolicy from "../shared/components/PrivacyRefundT&CPages/Refundpolicy";

export default function Approuter() {

    return (
        <BrowserRouter>
            <Routes>
                <Route element={<Main />}>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/aboutus" element={<Aboutus />} />
                    <Route path="/gallery" element={<Ourgallery />} />
                    <Route path="/application-form" element={<ApplicationformPage />} />
                    <Route path="/study-materials" element={<Studymaterials />} />
                    <Route path="/login" element={<Loginform />} />
                    <Route path="/terms-and-conditions" element={<TermsandConditions/>}/>
                    <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                    <Route path="/refund-cancellation-policy" element={<Refundpolicy/>}/>
                    <Route path="/register" element={<RegisterFormPage/>}/>
                    <Route path="/myaccount" element={<MyAccount/>}/>
                    <Route path="/myorder" element={<Myorders/>}/>
                    <Route path="/verify-otp" element={<VerifyOtp/>}/>
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/verify-forgot-password" element={<VerifyForgotPasswordOTP />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/view-product/:id" element={<Viewproductfunction/>}/>
                </Route>
                <Route
                    path="/dashboard/*"
                    element={
                        <ProtectedRoute allowedRoles={['Admin']['Student']}>
                            <AdminRouter />
                        </ProtectedRoute>
                    }
                />
            </Routes>
        </BrowserRouter>
    )
}
