import { useQueryClient } from "@tanstack/react-query";
import useForm from "../../admin/hooks/useForm";
import ApplicationForm from "../../shared/components/Home/Application-form";
import { apisaveStudApplication } from "../../shared/services/apistudapplication/apistudApplication";
import toast from "react-hot-toast";

export default function ApplicationformPage(initialValues) {
    const queryClient = useQueryClient();
    const { formdata, handlechange, resetForm } = useForm(initialValues);

    const handlesave = async (e) => {
        e.preventDefault();
        try {
            const res = await apisaveStudApplication(formdata);
            if (res.message === "Successfully saved") {
                handlesuccess(res);
            } else {
                toast.error(res.message);
            }
        } catch (error) {
            if (error.response && error.response.data) {
                toast.error(error.response.data.message);
            } else {
                toast.error("Email already exists in customer records. Please use a different email.");
            }
        }
    };

    const handlesuccess = (res) => {
        toast.success(res.message)
        queryClient.invalidateQueries('studentdata');
        resetForm();
    }

    return (
        <ApplicationForm
            formdata={formdata}
            handlesave={handlesave}
            handlechange={handlechange}
        />
    )
}