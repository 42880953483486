import axios from "axios";
import { gettoken } from "../../../../shared/services/token/token";
import apiurl from "../apiendpoint/apiendpoint";

export const apisavebooks = async (datas) => {
    try {
        const formData = new FormData();
        for (const key in datas) {
            if (Array.isArray(datas[key])) {
                datas[key].forEach(file => {
                    formData.append(key, file);
                });
            } else {
                formData.append(key, datas[key]);
            }
        }
        const token = gettoken();
        const res = await axios.post(`${apiurl()}/books/apisavebookdata`, formData, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "multipart/form-data"
            }
        });

        return res.data;
    } catch (err) {
        console.log(err);
    }
};


export const apigetallbooks = async (params) => {
    try {
        const token = gettoken();
        const res = await axios.get(`${apiurl()}/books/apigetbookdata`,{params:params,headers: { "Authorization": `Bearer ${token}` }});
        return res.data;
    } catch (err) {
        console.log(err);
    }
};

export const apiupdatebooks = async (_id, formData) => {
    try {
        const token = gettoken();
        const res = await axios.put(
            `${apiurl()}/books/apiupdatebooks/${_id}`, 
            formData,
            {
                headers: { 
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "multipart/form-data"
                }
            }
        );
        return res.data;
    } catch (err) {
        console.error("API Update Error:", err.response ? err.response.data : err);
        throw err;
    }
};

// export const apiupdatebooks = async (_id, datas) => {
//     try {
//         const formData = new FormData();
//         for (const key in datas) {
//             if (Array.isArray(datas[key])) {
//                 datas[key].forEach(file => {
//                     formData.append(key, file);
//                 });
//             } else {
//                 formData.append(key, datas[key]);
//             }
//         }
//         const token = gettoken();
//         const res = await axios.put(`${apiurl()}/books/apiupdatebooks/${_id}`, formData, {
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "multipart/form-data"
//             }
//         });

//         return res.data;
//     } catch (err) {
//         console.error("API Update Error:", err);
//         throw err;  // Re-throw to allow caller to handle
//     }
// };

export const apideletebooks = async (_id) => {
    try {
        const token = gettoken();
        const res = await axios.delete(`${apiurl()}/books/apideletebookdata/${_id}`,{headers: { "Authorization": `Bearer ${token}` }});
        return res.data;
    } catch (err) {
        console.log(err);
    }
};