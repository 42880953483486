/* eslint-disable react/prop-types */
import { Button } from "@nextui-org/react";
import moment from "moment-timezone";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { MultiSelect } from "primereact/multiselect";
import { getPurchaseFilterOptions } from "../../services/apipurchase/apipurchase";
import { useState } from "react";

export default function Tableview(props) {
  const { tabledata, editfrom, cusfilter, handleview,tempFilterValues, setTempFilterValues,onPage, page, downloadPDF,downloadingPDF } = props;

  const [filterOptions,setFilterOptions] = useState([]);

  const actionbotton = (rowData) => {
    return (
      <div className="flex gap-2">
        <button
          title="Payment Status"
          onClick={() => editfrom(rowData)}
          className="inline-flex items-center text-xl font-medium text-green-600 gap-x-1 decoration-2 "
        >
          <i className="fi fi-rr-pen-circle"></i>
        </button>
        <button
          title="View Purchase"
          onClick={()=>handleview(rowData)}
          className="inline-flex items-center text-xl font-medium text-blue-600 gap-x-1 decoration-2 "
        >
          <i className="fi fi-rr-eye"></i>
        </button>
        <button title="Download PDF"
        onClick={() => downloadPDF(rowData.Purchase_id)} 
        className="inline-flex items-center text-xl font-medium text-red-600 gap-x-1 decoration-2"
        disabled={downloadingPDF[rowData.Purchase_id]}
      >
        {downloadingPDF[rowData.Purchase_id] ? (
          <i className="fas fa-spinner fa-spin"></i>
        ) : (
          <i className="text-red-500 fi fi-rr-file-pdf"></i>
        )}
      </button>
      </div>
    );
  };

  const handleApplyFilters = (key) => {
    cusfilter(key, tempFilterValues[key]);
    onPage(page);
};

const handleClearFilters = (key) => {
    setTempFilterValues(prev => ({ ...prev, [key]: null }));
    cusfilter(key, null);
    onPage(1);
};

  const Filter = (key) => (
    <div onClick={()=>getOption(key)}>
      <MultiSelect value={tempFilterValues[key?.field]} options={filterOptions} optionLabel="value" className="p-column-filter" virtualScrollerOptions={{ itemSize: 43 }} maxSelectedLabels={1}
        filter onChange={(e) => setTempFilterValues(prev => ({ ...prev, [key?.field]: e.value }))} placeholder={`Select ${key?.field.charAt(0).toUpperCase() + key.field.slice(1)}`}
          panelFooterTemplate={
          <div className="flex justify-between p-2 mt-2">
             <Button onClick={() => handleClearFilters(key.field)} className="p-2 w-[45%] text-white text-base font-bold bg-blue-500">Clear</Button>
             <Button onClick={() => handleApplyFilters(key.field)} className="p-2 w-[45%] text-white text-base font-bold bg-blue-500">Apply</Button>
          </div>
        } 
      />
    </div>
);

  const getOption = async (key)=>{
    var filterOptions = await getPurchaseFilterOptions(key?.field);
    //console.log(filterOptions)
    var formatoption = filterOptions[key.field].map( val =>({ label:val,value: key.format == "Date"? moment(val).format('YYYY-MM-DD') :val}));
    setFilterOptions(formatoption);
}

  const columns = [
    { field: "Order_Date", header: "Order Date", format: "Date", width: "150px"},
    { field: "First_Name", header: "First Name", width: "150px" },
    { field: "Last_Name", header: "Last Name", width: "150px" },
    { field: "Mobilenumber", header: "Mobile Number", width: "150px" },
    { field: "Address", header: "Address", width: "200px" },
    { field: "City", header: "City", filter: true, width: "150px" },
    { field: "State", header: "State", width: "150px" },
    { field: "Zipcode", header: "Pincode", width: "150px", filter: true },
  ];

  return (
    <>
      <div>
        <DataTable
          value={tabledata}
          scrollable
          scrollHeight="680px"
          className="!text-sm bg-white border mt-3 rounded-xl"
          stateStorage="session"
          stateKey="dt-state-demo-local"
        >
          <Column header="Action" body={actionbotton} />
          {/* <Column header="Images"  body={image} /> */}
          {columns.map((col, i) => (
            <Column
              key={i}
              field={col.field}
              header={col.header}
              filter={col.filter}
              filterElement={Filter(col)}
              showFilterMenuOptions={false}
              showApplyButton={false}
              showClearButton={false}
              showFilterMatchModes={false}
              style={{ minWidth: col.width }}
              body={(rowData, meta) => {
                if (col.format == "Date") {
                  return moment(rowData[meta.field]).format("YYYY-MM-DD");
                } else if (col.format == "HTML") {
                  return (
                    <div
                      dangerouslySetInnerHTML={{ __html: rowData[meta.field] }}
                    />
                  );
                } else {
                  return rowData[meta.field];
                }
              }}
            />
          ))}
        </DataTable>
      </div>
    </>
  );
}
