/* eslint-disable react/prop-types */
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function ViewProductSEO ({product }) {

    return(
        <>
                <HelmetProvider>
                    <Helmet>
                    <meta charset="UTF-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <title>Kavin&apos;s Academy - View Products</title>

                    <meta name="keywords" content="Kavins Academy Salem,UPSC coaching Salem,TNPSC coaching Salem,Competitive exams coaching Tamil Nadu,IBPS coaching Salem,TET coaching Salem,TRB coaching Salem,DRB coaching Salem,Best UPSC classes Salem,Best TNPSC coaching Salem,Online coaching for UPSC TNPSC,Offline classes for competitive exams Salem,Competitive exam coaching Salem Tamil Nadu,UPSC TNPSC IBPS coaching Kavins Academy,Government exams coaching Salem,Bank exams coaching Salem,Kavins Academy competitive exams,Salem Tamil Nadu coaching classes,UPSC classes Tamil Nadu,TNPSC coaching Tamil Nadu,Kavins Academy ,UPSC coaching center,TNPSC coaching ,Competitive exams coaching Tamil Nadu,IBPS coaching ,TET coaching Salem,TRB coaching ,DRB coaching ,Best UPSC classes Salem,Best TNPSC coaching ,Online coaching for UPSC TNPSC,Offline classes for competitive exams ,Competitive exam coaching  Tamil Nadu,UPSC TNPSC IBPS coaching Kavins Academy,Government exams coaching ,Bank exams coaching ,Kavins Academy UPSC coaching
                    TNPSC coaching center,Competitive exams coaching Tamil Nadu,IBPS coaching institute,TET coaching classes,TRB coaching for teachers,DRB coaching center,UPSC online coaching
                    TNPSC online classes,Competitive exams preparation Tamil Nadu,Best coaching for UPSC TNPSC,Bank exams coaching institute,Government exams coaching center,Online and offline coaching for competitive exams,UPSC TNPSC IBPS coaching,Top competitive exams institute,UPSC preparation classes,Coaching classes for government exams,Tamil Nadu exam coaching,Kavins Academy exam preparation" />

                    <meta name="description" content="Kavins Academy is a premier coaching institute specializing in competitive exam preparation for aspirants across Tamil Nadu. 
                    We offer both online and offline classes tailored for exams such as UPSC, TNPSC, IBPS, TET-TRB, and SRD/DRB (Cooperative)." />

                    <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1" />
                    <meta property="og:type" content="website" />
                    <meta property="og:image" content="/images/logo/favicon.png" />

                    <meta property="og:title" content="Kavin's Academy - An Ultimate Destination fo UPSC, TNPSC, IBPS, TET-TRB, SRD/DRB (Coop) Aspirants" />

                    <meta property="og:description" content="Kavins Academy is a premier coaching institute specializing in competitive exam preparation for aspirants across Tamil Nadu. 
                    We offer both online and offline classes tailored for exams such as UPSC, TNPSC, IBPS, TET-TRB, and SRD/DRB (Cooperative)." />

                    <meta property="og:url" content={`https://www.kavinsacademy.com/view-product/${product?._id}`} />
                    <meta property="og:site" content="Kavin's Academy" />
                    <meta property="og:site_name" content="Kavin's Academy" />
                    <link rel="canonical" href={`https://www.kavinsacademy.com/view-product/${product?._id}`} />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:site" content="@kavinsacademy" />
                    <meta name="twitter:title" content="Kavin's Academy | Distance Education in Tamil Nadu" />
                    <meta name="twitter:description" content="With a dedicated team of experienced educators, we provide comprehensive study materials, personalized coaching, and result-oriented strategies to help students achieve their goals. " />
                    <meta name="twitter:image" content="/images/logo/favicon.png" />

                    </Helmet>
                </HelmetProvider>
        
        </>
    )
}