/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import useAuth from "../../../../../shared/services/store/useAuth";
import { apigetallstuddashcourse } from "../../../services/apistudentdashboard/apistudentDash";
import apiurl from "../../../services/apiendpoint/apiendpoint";

export default function Studenthome() {
    const { userdetails } = useAuth();
    const [studentData, setStudentData] = useState(null);
    let isMounted = true;

    const fetchStudentData = useCallback(async () => {
        if (userdetails?.Role === "Student") {
            try {
                const data = await apigetallstuddashcourse({ studentId: userdetails._id });
                setStudentData(data);
            } catch (err) {
                console.error("Failed to fetch student data", err);
            }
        }
    }, [userdetails]);

    useEffect(() => {
        if (isMounted) { fetchStudentData(); }
        return () => { isMounted = false; };
    }, [fetchStudentData]);

    if (!userdetails) {
        return <div>                    
            <div className="w-16 h-16 border-4 border-t-4 border-blue-500 rounded-full animate-spin"></div>
            </div>;
    }

  
  
    console.log(`${apiurl()}/${encodeURI(studentData?.studentData?.ProfileImage)}`);

    const displayName = userdetails.Role === "Admin" ? "Admin" : (studentData?.studentData?.Full_name || userdetails.Full_name);

    return (
        <>
            <div className='grid justify-center grid-cols-1 mb-5 shadow-xl'>
                <div className='bg-regal-blue bg-gradient-to-l from-regal-yellow to-regal-blue rounded-xl'>
                    <div className='grid grid-cols-1 lg:grid-cols-2'>
                        <div className='flex items-center justify-center p-2'>
                            <p className='text-2xl font-medium text-white lg:text-4xl'>
                                Welcome {displayName}
                            </p>
                        </div>
                        <div className='flex items-center justify-center'>
                            <img  src={`${apiurl()}/${studentData?.studentData?.ProfileImage}`} alt="" className="w-32 h-32 rounded-full" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="my-5 shadow-xl bg-regal-yellow w-fit rounded-xl">
                {userdetails.Role === "Student" && studentData && (
                    <div className='p-4'>
                        <div className="grid grid-cols-1 lg:grid-cols-2">
                            <div className="flex items-center justify-center">
                                <img src="/images/online-course.png" alt="Courses" className="w-32 h-32" />
                            </div>
                            <div>
                                <h3 className='text-xl font-semibold'>Your Courses:</h3>
                                <ul className='my-5 ml-5 space-y-2 list-disc'>
                                    {studentData.coursesData?.length > 0 ? (
                                        studentData.coursesData.map((course, index) => (
                                            <li key={index}>{course.Course_Title} ({course.Course_Name})</li>
                                        ))
                                    ) : (
                                        <li>No courses found</li>
                                    )}
                                </ul>
                                <p className='mt-4'>Status: {studentData.studentData.Status}</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
