import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { apiverifyotpforgotpassword } from "../../services/apiauthentication/apiregister";

export function VerifyForgotPasswordOTP() {
    const [OTP, setOTP] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const email = new URLSearchParams(window.location.search).get("email");
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
        const response = await apiverifyotpforgotpassword({ Email: email, OTP });
        if (response.status === "Sucessfully otp verified") {
          toast.success("OTP verified successfully!");
          navigate(`/reset-password?email=${email}`);
        } else {
          toast.error(response.status);
        }
      } catch (error) {
        console.log(error);
        toast.error("An error occurred. Please try again.");
      }
      setLoading(false);
    };
  
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="max-w-[30rem] mx-auto w-full px-4">
          <div className="p-8 bg-white border shadow-xl rounded-xl">
            <h1 className="mb-6 text-2xl font-bold text-center">Verify OTP</h1>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-lg">Enter OTP</label>
                <input
                  type="text"
                  value={OTP}
                  onChange={(e) => setOTP(e.target.value)}
                  className="w-full px-4 py-2 border outline-none rounded-xl"
                  required
                />
              </div>
              <div className="flex items-center justify-center">
                <button
                  type="submit"
                  className="inline-flex items-center justify-center px-6 py-3 text-xl font-bold text-white rounded-lg bg-regal-blue"
                  disabled={loading}
                >
                  {loading ? "Verifying..." : "Verify OTP"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }