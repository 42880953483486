/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { apigetcourseName } from '../../services/courses/apicourses';

export default function Addaneditform(props) {
    const { visible, setVisible, formdata, handlechange, handlesave, handleupdate } = props;
    const [pdfFiles, setPdfFiles] = useState([]);
    const [courseNames, setCourseNames] = useState([]);

   
    useEffect(() => {
        const files = formdata['Course_Pdf'] ? 
            Array.isArray(formdata['Course_Pdf']) ? 
                formdata['Course_Pdf'] 
                : [formdata['Course_Pdf']] 
            : [];
        setPdfFiles(files);
    }, [formdata]);

    const handlePdfChange = (e) => {
        const files = Array.from(e.target.files);
        
        // Combine existing files with new files, limiting to 5
        const updatedFiles = [...pdfFiles, ...files].slice(0, 5);
        
        setPdfFiles(updatedFiles);
        handlechange({ target: { name: 'Course_Pdf', value: updatedFiles } });
    };

    const removeFile = (indexToRemove) => {
        const updatedFiles = pdfFiles.filter((_, index) => index !== indexToRemove);
        setPdfFiles(updatedFiles);
        handlechange({ target: { name: 'Course_Pdf', value: updatedFiles } });
    };

    useEffect(() => {
        const fetchCourseNames = async () => {
            try {
                const data = await apigetcourseName();
                if (data && data.resdata) {
                    setCourseNames(data.resdata);
                }
            } catch (error) {
                console.error('Error fetching course names:', error);
            }
        };

        fetchCourseNames();
    }, []);
    return (
        <Dialog header="Course Form" visible={visible} onHide={() => { if (!visible) return; setVisible(false); }} style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }} maximizable
        >
            <form onSubmit={!formdata._id ? handlesave : handleupdate}>
                <div className="grid grid-cols-1 gap-y-2">
                    <div>
                        <div>
                            <label>Course Title</label>
                        </div>
                        <input type="text" name="Course_Title" value={formdata['Course_Title']} className="w-full px-4 py-2 mt-1 border outline-none rounded-xl" onChange={handlechange} required
                        />
                    </div>
                    <div>
                        <div>
                            <label>Course Name</label>
                        </div>
                        <select name="Course_Name" value={formdata['Course_Name']}
                            className="w-full px-4 py-2 mt-1 border outline-none rounded-xl" onChange={handlechange} required
                        >
                            <option value="">
                                ---Select a Course---
                            </option>
                            {courseNames.map((course, index) => (
                                <option key={index} value={course.name}>
                                    {course.courseName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <div><label>Batch</label></div>
                        <input type="text" name="Batch" value={formdata['Batch']} className="w-full px-4 py-2 mt-1 border outline-none rounded-xl" onChange={handlechange} required
                        />
                    </div>
                    <div>
                    <div>
                        <label>Course PDFs (Max 5)</label>
                    </div>
                    <input type="file" multiple accept=".pdf" name="Course_Pdf" className="w-full px-4 py-2 mt-1 border outline-none rounded-xl" onChange={handlePdfChange} />
                    {pdfFiles.length > 0 && (
                        <div className="mt-2">
                            {pdfFiles.map((file, index) => (
                                <div key={index}  className="flex items-center justify-between mb-1 text-sm text-gray-600"
                                >
                                    <span>
                                        {/* Handle both File objects and existing file paths */}
                                        {file.name || (typeof file === 'string' ? file : 'Unknown file')}
                                    </span>
                                    <button type="button" onClick={() => removeFile(index)} className="ml-2 text-red-500">Remove</button>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                    <div>
                        <div>
                            <label>Course Video</label>
                        </div>
                        <input type="text" name="Course_Video" value={formdata['Course_Video']} className="w-full px-4 py-2 mt-1 border outline-none rounded-xl" onChange={handlechange}
                            required
                        />
                    </div>
                    <div>
                        <div>
                            <label>Status</label>
                        </div>
                        <select name="Status" value={formdata['Status']} className="w-full px-4 py-2 mt-1 border outline-none rounded-xl" onChange={handlechange} required >
                            <option disabled value=""> ---Select a Status--- </option>
                            <option>Active</option>
                            <option>Inactive</option>
                        </select>
                    </div>
                </div>

                <div className="flex justify-end gap-5 mt-5">
                    <button type="button" onClick={() => setVisible(false)}>
                        Cancel
                    </button>
                    <button color="primary" type="submit" className="px-4 py-2 text-white bg-secondary rounded-xl" >
                        <i className="fi fi-br-check"></i> {!formdata._id ? 'Save' : 'Update'}
                    </button>
                </div>
            </form>
        </Dialog>
    );
}
