import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useCartStore from "../../shared/services/store/usecart";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import useAuth from "../../shared/services/store/useAuth";
import Cartsidebar from "../../shared/components/CheckoutandCart/Cartsidebar";


export default function Header() {

  const location = useLocation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { logout, userdetails } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    setIsMobileMenuOpen(false);
    if (location.hash === "#contact-us") {
      setTimeout(() => {
        const element = document.getElementById("contact-us");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 0);
    }
  }, [location]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(prevState => !prevState);
  };

  const closeDropdown = (e) => {
    // Close dropdown if clicking outside of it
    if (e.target.closest("#hs-dropdown") === null) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeDropdown);
    return () => {
      document.removeEventListener("click", closeDropdown);
    };
  }, []);

  useEffect(() => {
    const sectionIds = document.querySelectorAll('a.my-link');
    const mobileMenu = document.getElementById("navbar-collapse-with-animation");

    sectionIds.forEach(link => {
      link.addEventListener("click", () => {
        mobileMenu.classList.remove("block");
        mobileMenu.classList.add("hidden");
      });
    });

    return () => {
      sectionIds.forEach(link => {
        link.removeEventListener("click", () => {
          mobileMenu.classList.remove("block");
          mobileMenu.classList.add("hidden");
        });
      });
    };
  }, []);

  const { cart } = useCartStore();

  const isLinkActive = (path) => {
    return location.pathname === path ? "text-regal-yellow" : "text-gray-800 hover:text-regal-yellow";
  };
  const getDisplayLetter = () => {
    if (userdetails?.Role === 'Admin' || userdetails?.Role === 'Student') {
      return userdetails.Username?.charAt(0).toUpperCase();
    } else{
    return userdetails?.First_Name?.charAt(0).toUpperCase();
    }
  };

  return (
    <header className="sticky top-0 z-50 flex flex-wrap w-full py-5 text-sm bg-white border-b sm:justify-start sm:flex-nowrap sm:py-0">
      <nav className="relative overflow-hidden max-w-[110rem] w-full mx-auto px-4 sm:flex sm:items-center justify-center sm:justify-between sm:px-6 lg:px-8" aria-label="Global">
        <div className="flex items-center justify-between">
          <a className="flex-none text-xl font-semibold" href="/" aria-label="Brand">
            <img src="/images/logo/KAVINS ACADEMY logo.png" alt="" className="w-auto h-10 lg:h-12" />
          </a>
          <div className="sm:hidden">
            <button
              id="mobile-menu"
              type="button"
              className="inline-flex items-center justify-center gap-2 p-2 text-sm font-medium text-gray-700 align-middle transition-all bg-white border rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600"
              onClick={toggleMobileMenu}
              aria-expanded={isMobileMenuOpen}
            >
              <svg className={`w-4 h-4 ${isMobileMenuOpen ? 'hidden' : 'block'}`} width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
              </svg>
              <svg className={`w-4 h-4 ${isMobileMenuOpen ? 'block' : 'hidden'}`} width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg>
            </button>
          </div>
        </div>
        <div id="navbar-collapse-with-animation" className={`${isMobileMenuOpen ? 'block' : 'hidden'} overflow-hidden transition-all duration-300 basis-full grow sm:block`}>
          <div className="flex flex-col mt-5 gap-y-4 gap-x-0 sm:flex-row sm:items-center sm:justify-center sm:gap-y-0 sm:gap-x-7 sm:mt-0 sm:pl-7">
          <div className="flex flex-col my-header-nav gap-y-4 sm:flex-row sm:items-center sm:justify-center gap-x-2 sm:gap-y-0 sm:gap-x-7 sm:mt-0 sm:pl-7 sm:ml-auto">
              <Link className={`text-xl font-semibold ${isLinkActive("/")} sm:py-6 my-link`} data-scroll to="/" onClick={scrollToTop}>Home</Link>
              <Link className={`text-xl font-semibold ${isLinkActive("/aboutus")} sm:py-6`} to="/aboutus" onClick={scrollToTop}>About Us</Link>
              <Link className={`text-xl font-semibold ${isLinkActive("/gallery")} sm:py-6`} to="/gallery" onClick={scrollToTop}>Gallery</Link>
              <div id="hs-dropdown" className={`hs-dropdown [--strategy:static] sm:[--strategy:fixed] [--adaptive:none] [--auto-close:outside] ${isDropdownOpen ? 'open' : ''}`}>
                <Link to="/study-materials" id="hs-mega-menu-basic-dr" className={`flex items-center w-full text-xl font-semibold ${isLinkActive("/study-materials")} sm:py-6 my-link`} onClick={toggleDropdown}>
                  Shop
                </Link>
              </div>
              <Link className="text-xl font-semibold text-gray-800 hover:text-regal-yellow sm:py-6 my-link" data-scroll to="/#contact-us">Contact Us</Link>
            </div>
            <div className="flex gap-x-3">
              {/* <div className="flex items-center gap-x-2 sm:ml-auto">
                <Link onClick={scrollToTop} data-scroll className="inline-flex items-center justify-center px-6 py-3 text-xl font-bold leading-6 text-center text-white transition-all duration-300 ease-in-out rounded-lg my-link gap-x-3 bg-regal-blue hover:bg-gradient-to-l hover:from-regal-yellow hover:to-regal-blue" to="/login">
                  Login
                </Link>
              </div> */}
              <div className="flex items-center gap-x-2 sm:ml-auto">
                <Link onClick={scrollToTop} data-scroll className="inline-flex items-center justify-center px-6 py-3 text-xl font-bold leading-6 text-center text-white transition-all duration-300 ease-in-out rounded-lg my-link gap-x-3 bg-regal-blue hover:bg-gradient-to-l hover:from-regal-yellow hover:to-regal-blue" to="/application-form">
                  Apply Now
                </Link>
              </div>
              <div className="relative cart">
                <FontAwesomeIcon icon={faShoppingCart} size="2x" className="mx-2 mt-4 cursor-pointer" onClick={toggleSidebar} />
                <span className="absolute top-0 right-0 flex items-center justify-center w-5 h-5 text-xs text-white bg-red-600 rounded-full">
                  {cart.length}
                </span>
              </div>

             <div className="hs-dropdown [--placement:bottom-right] relative inline-flex mt-2">
             <button type="button"
                className="w-[2.375rem] h-[2.375rem] inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
              >
                {userdetails ? (
                  <div className="inline-flex items-center justify-center w-[38px] h-[38px] rounded-full bg-regal-blue ring-2 ring-white">
                    <span className="text-lg font-bold text-white">
                      {getDisplayLetter()}
                    </span>
                  </div>
                ) : (
                  <div className="inline-flex items-center justify-center w-[38px] h-[38px] rounded-full bg-gray-100 ring-2 ring-white">
                    <i className="text-xl text-gray-500 fi fi-rr-user"></i>
                  </div>
                )}
              </button>

                <div className="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-60 bg-white shadow-md rounded-lg p-2  " aria-labelledby="hs-dropdown-with-header">
                  <div className="px-5 py-3 -m-2 rounded-t-lg bg-secondary ">
                    <p className="text-sm text-white ">Signed in as {userdetails?.First_Name}</p>
                    <p className="mt-3 text-sm font-medium text-white">{userdetails?.Email}</p>
                  </div>
                  <div className="py-2 mt-2 first:pt-0 last:pb-0">

                    {userdetails ? (
                      <>
                       {userdetails?.Role === "Admin" && (
                        <>
                        <Link to="/dashboard/home" className="flex  items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 cursor-pointer  ">
                        <i className="fi fi-rr-dashboard-panel"></i>
                          Admin Dashboard
                        </Link>
                         <Link to="/myaccount" type="button" className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500">
                         <i className="mt-1 font-semibold fi fi-rr-portrait"></i>
                           My Account
                         </Link>
                         <Link to="/myorder" type="button" className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500">
                         <i className="mt-1 fi fi-br-order-history"></i>
                           My Order
                         </Link>
                         </>
                       )}
                        {userdetails?.Role === "Student" && (
                        <Link to="/dashboard/studenthome" className="flex  items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 cursor-pointer  ">
                        <i className="fi fi-rr-dashboard-panel"></i>
                          My Dashboard
                        </Link>
                       )}
                        {userdetails?.Role === "Customer" && (
                          <>
                        <Link to="/myaccount" type="button" className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500">
                        <i className="mt-1 font-semibold fi fi-rr-portrait"></i>
                          My Account
                        </Link>
                        <Link to="/myorder" type="button" className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500">
                        <i className="mt-1 fi fi-br-order-history"></i>
                          My Order
                        </Link>
                       
                        </>
                        )}
                         <Link onClick={handleLogout} type="button" className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500">
                        <i className="mt-1 fi fi-br-sign-out-alt"></i>
                          Logout
                        </Link>
                      </>
                    ) : (
                      <>
                      
                        <Link to="/login" className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500">
                        <i className="mt-1 fi fi-br-sign-in-alt"></i>
                          Login
                        </Link>
                      </>
                    )}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </nav>
      <Cartsidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} />
    </header>
  );
}
