/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { Tooltip } from "@nextui-org/react";
import { NavLink } from "react-router-dom";
import useAuth from "../../shared/services/store/useAuth";

export default function Sidebar({ isOpen }) {
    const { userdetails } = useAuth();
    const [isMobileOpen, setIsMobileOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth < 1024);
        };
        
        checkMobile();
        window.addEventListener('resize', checkMobile);
        
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    useEffect(() => {
        if (!isMobile) {
            setIsMobileOpen(false);
        }
    }, [isMobile]);

    return (
        <>
            {/* Mobile Header */}
            <div className="sticky inset-x-0 top-0 z-20 px-4 bg-white border-y sm:px-6 md:px-8 lg:hidden">
                <div className="flex items-center justify-between py-2">
                    <ol className="flex items-center ms-3 whitespace-nowrap">
                        <li className="flex items-center text-sm text-gray-800">
                            Application
                            <svg className="flex-shrink-0 mx-3 overflow-visible size-2.5 text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 1L10.6869 7.16086C10.8637 7.35239 10.8637 7.64761 10.6869 7.83914L5 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
                            </svg>
                        </li>
                        <li className="text-sm font-semibold text-gray-800 truncate" aria-current="page">
                            Dashboard
                        </li>
                    </ol>
                    <button 
                        type="button" 
                        className="flex items-center justify-center gap-x-1.5 px-3 py-2 text-gray-500 transition-colors border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-gray-600"
                        onClick={() => setIsMobileOpen(!isMobileOpen)}
                    >
                        <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <path d="M17 8L21 12L17 16M3 12H13M3 6H13M3 18H13"/>
                        </svg>
                        <span className="sr-only">Toggle Sidebar</span>
                    </button>
                </div>
            </div>

            {/* Overlay for mobile */}
            {isMobile && isMobileOpen && (
                <div 
                    className="fixed inset-0 z-[50] bg-black bg-opacity-50 transition-opacity lg:hidden"
                    onClick={() => setIsMobileOpen(false)}
                />
            )}

            {/* Sidebar */}
            <div className={`fixed inset-y-0 start-0 z-[60] bg-[#1a1f37] shadow-xl border-r border-gray-800/10 transition-all duration-300 transform
                ${isMobile 
                    ? isMobileOpen 
                        ? 'translate-x-0 w-64' 
                        : '-translate-x-full'
                    : isOpen 
                        ? 'w-64' 
                        : 'w-20'
                }
                ${isMobile ? 'lg:translate-x-0' : ''}
            `}>
                <div className="flex flex-col items-center justify-center px-2 pt-6 pb-8">
                    <div className="p-2 shadow-lg bg-white/10 rounded-2xl backdrop-blur-sm">
                        <img src="/images/logo/favicon.png" className="h-[40px] rounded-xl" alt="Kavins Logo" />
                    </div>
                    {(isOpen || (isMobile && isMobileOpen)) && (
                        <h2 className="mt-3 text-sm font-medium text-white/90">Kavins Academy</h2>
                    )}
                </div>

                <nav className="flex flex-col flex-wrap w-full py-4">
                    <ul className="space-y-1.5 px-3">
                        {userdetails?.Role === "Admin" && (
                            <>
                                <NavItem icon="fi-rr-apps" label="Dashboard" to="/dashboard/home" 
                                    isOpen={isMobile ? isMobileOpen : isOpen} />
                                <NavItem icon="fi-sr-form" label="Applications" to="/dashboard/applications" 
                                    isOpen={isMobile ? isMobileOpen : isOpen} />
                                <NavItem icon="fi-ss-user-graduate" label="Students" to="/dashboard/students" 
                                    isOpen={isMobile ? isMobileOpen : isOpen} />
                                <NavItem icon="fi-sr-graduation-cap" label="Courses" to="/dashboard/courses" 
                                    isOpen={isMobile ? isMobileOpen : isOpen} />
                                <NavItem icon="fi-sr-users-class" label="Class Details" to="/dashboard/onandupcomeclass" 
                                    isOpen={isMobile ? isMobileOpen : isOpen} />
                                <NavItem icon="fi-ss-book-open-cover" label="Products" to="/dashboard/add-products" 
                                    isOpen={isMobile ? isMobileOpen : isOpen} />
                                <NavItem icon="fi-ss-order-history" label="Orders" to="/dashboard/orders" 
                                    isOpen={isMobile ? isMobileOpen : isOpen} />
                                <NavItem icon="fi fi-rr-marketplace-alt" label="Purchase" to="/dashboard/purchase" 
                                    isOpen={isMobile ? isMobileOpen : isOpen} />
                                <NavItem icon="fi-sr-user-headset" label="Customers" to="/dashboard/customers" 
                                    isOpen={isMobile ? isMobileOpen : isOpen} />
                            </>
                        )}

                        {userdetails?.Role === "Student" && (
                            <>
                                <NavItem icon="fi-rr-apps" label="Dashboard" to="/dashboard/studenthome" 
                                    isOpen={isMobile ? isMobileOpen : isOpen} />
                                <NavItem icon="fi-ss-lesson" label="View Courses" to="/dashboard/studentcourses" 
                                    isOpen={isMobile ? isMobileOpen : isOpen} />
                            </>
                        )}
                    </ul>
                </nav>
            </div>
        </>
    );
}

const NavItem = ({ icon, label, to, isOpen }) => (
    <li>
        {isOpen ? (
            <NavLink
                to={to}
                className={({ isActive }) => `flex items-center w-full gap-3 px-4 py-3 rounded-xl transition-all duration-200
                    ${isActive 
                        ? 'bg-gradient-to-r from-[#F5C231] to-[#f5a531] text-white shadow-lg shadow-[#F5C231]/20' 
                        : 'text-gray-400 hover:text-white hover:bg-white/10'}
                `}
            >
                <i className={`text-xl ${icon} flex-shrink-0`}></i>
                <span className="text-sm font-medium whitespace-nowrap">{label}</span>
            </NavLink>
        ) : (
            <Tooltip content={label} placement="right-end" showArrow={true}>
                <NavLink
                    to={to}
                    className={({ isActive }) => `flex items-center justify-center w-full p-3 rounded-xl transition-all duration-200
                        ${isActive 
                            ? 'bg-gradient-to-r from-[#F5C231] to-[#f5a531] text-white shadow-lg shadow-[#F5C231]/20' 
                            : 'text-gray-400 hover:text-white hover:bg-white/10'}
                    `}
                >
                    <i className={`text-xl ${icon}`}></i>
                </NavLink>
            </Tooltip>
        )}
    </li>
);