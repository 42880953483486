import axios from "axios";
import apiurl from "../apiendpoint/apiendpoint";
import { gettoken } from "../../../../shared/services/token/token";

export const apigetallcourse = async (params) => {
    try {
        const token = gettoken();
        const res = await axios.get(`${apiurl()}/course/apigetallcourse`,{params:params,headers: { "Authorization": `Bearer ${token}` }});
        return res.data;
    } catch (err) {
        console.log(err);
    }
};

export const apisavecourse = async (datas) => {
    try {
        const formData = new FormData();
        
        // Append all non-file fields
        for (const key in datas) {
            if (key !== 'Course_Pdf') {
                formData.append(key, datas[key]);
            }
        }
        
        // Append multiple PDF files
        if (datas.Course_Pdf && datas.Course_Pdf.length > 0) {
            datas.Course_Pdf.forEach((file, index) => {
                formData.append('Course_Pdf', file);
            });
        }

        const token = gettoken();
        const res = await axios.post(`${apiurl()}/course/apisavecourse`, formData, { 
            headers: { 
                "Authorization": `Bearer ${token}`,
                "Content-Type": "multipart/form-data"
            }
        });

        return res.data;
    } catch (err) {
        console.log(err);
        throw err; // Re-throw to handle in the calling function
    }
};

export const apiupdatecourse = async (_id,datas) => {
    try {
        const token = gettoken();
        const res = await axios.put(`${apiurl()}/course/apiupdatecourse/${_id}`, datas,{headers: { "Authorization": `Bearer ${token}` }});
        return res.data;
    } catch (err) {
        console.log(err);
    }
};
export const apideletecourse = async (_id) => {
    try {
        const token = gettoken();
        const res = await axios.delete(`${apiurl()}/course/apideletecourse/${_id}`,{headers: { "Authorization": `Bearer ${token}` }});
        return res.data;
    } catch (err) {
        console.log(err);
    }
};

export const apigetallclassforadd = async (params) => {
    try {
        const token = gettoken();
        const res = await axios.get(`${apiurl()}/course/apigetcourseforadd`,{params:params,headers: { "Authorization": `Bearer ${token}` }});
        return res.data;
    } catch (err) {
        console.log(err);
    }
};
// class names
export const apisavecourseName = async (datas) => {
    try {
        const token = gettoken();
        const res = await axios.post(`${apiurl()}/course/apisavecourseName`, datas, { headers: { "Authorization": `Bearer ${token}`, "Content-Type": "application/json" }});

        return res.data;
    } catch (err) {
        console.log(err);
    }
};

export const apigetcourseName = async (params) => {
    try {
        const token = gettoken();
        const res = await axios.get(`${apiurl()}/course/apigetCourseName`,{params:params,headers: { "Authorization": `Bearer ${token}` }});
        return res.data;
    } catch (err) {
        console.log(err);
    }
};
