import axios from "axios";
import apiurl from "../apiendpoint/apiendpoint";
import { gettoken } from "../../../../shared/services/token/token";

export const getalldashCustomers = async(params)=>{
    var res=await axios.get(`${apiurl()}/dashboard/getalldashCustomers`,{params:params, headers: {"Authorization" : `Bearer ${gettoken()}`}});
    return res.data;
 }

 export const getalldashproducts = async(params)=>{
    var res=await axios.get(`${apiurl()}/dashboard/getallsavedproducts`,{params:params, headers: {"Authorization" : `Bearer ${gettoken()}`}});
    return res.data;
 }

 export const getalldashorders = async(params)=>{
   var res=await axios.get(`${apiurl()}/dashboard/getalldashorders`,{params:params, headers: {"Authorization" : `Bearer ${gettoken()}`}});
   return res.data;
}