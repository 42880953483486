import { useEffect, useState } from "react";
import { apigetallstuddashcourse } from "../../../services/apistudentdashboard/apistudentDash";
import { Link } from "react-router-dom";

export default function ViewCourses() {
    const [courses, setCourses] = useState([]);

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const params = {};
                const data = await apigetallstuddashcourse(params);
                setCourses(data.coursesData);
                console.log("Fetched courses:", data.coursesData);
            } catch (error) {
                console.error("Error fetching courses:", error);
            }
        };

        fetchCourses();
    }, []);

    return (
        <>
            <div className="flex flex-col md:items-center md:justify-between md:flex-row">
                <div><h2 className="mb-2 text-2xl font-bold md:mb-0">View Course</h2></div>
                <div className="flex items-center gap-4">
                    <div className="relative min-w-64 md:min-w-80">
                        <div className="absolute inset-y-0 z-20 flex items-center pointer-events-none start-0 ps-4">
                            <svg className="flex-shrink-0 text-gray-400 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <circle cx="11" cy="11" r="8" />
                                <path d="m21 21-4.3-4.3" />
                            </svg>
                        </div>
                        <input type="text" id="icon" name="icon" className="block w-full px-4 py-3 text-sm border border-gray-200 outline-none rounded-xl ps-11 disabled:opacity-50 disabled:pointer-events-none" placeholder="Search course ..." />
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4">
                {courses.map((course) => (
                    <Link
                        to={`/dashboard/course/${course._id}`}
                        key={course._id}
                        className="overflow-hidden transition-shadow duration-300 bg-white rounded-lg shadow-md hover:shadow-lg"
                    >
                        <div className="p-6">
                            <h3 className="mb-2 text-xl font-semibold">{course.Course_Title}</h3>
                            <p className="mb-4 text-gray-600">{course.Course_Name}</p>
                            <div className="flex items-center justify-between">
                                <span className="text-sm text-gray-500">
                                    {new Date(course.createdAt).toLocaleDateString()}
                                </span>
                                <span className={`px-2 py-1 rounded text-xs ${
                                    course.Status === "Active" ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"
                                }`}>
                                    {course.Status}
                                </span>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </>
    );
}
