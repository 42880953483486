/* eslint-disable react/prop-types */

import { Link } from "react-router-dom";


export default function Registerform (props) {

  const { handleRegister, handlechange, loading} = props;
    

    return(
        <div className="flex items-center justify-center lg:h-screen ">
        <div className="max-w-[45rem] mx-auto w-full px-4 ">
            <div className="p-8 bg-white border shadow-xl rounded-xl">
               
                    <div className="flex justify-center mt-1 mb-7">
                        <img src="/images/logo/KAVINS ACADEMY logo.png" alt="" className="h-[40px] object-cover" />
                    </div>
                    <form onSubmit={handleRegister}>
                    <div className="grid grid-cols-1 gap-2 lg:grid-cols-2">
                    <div className="mb-3">
                        <label className="text-lg">First Name</label>
                        <div className="mt-1">
                            <input type="text" name="First_Name" id="First_Name" className="w-full px-4 py-2 border outline-none rounded-xl" onChange={handlechange}  required />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="text-lg">Last Name</label>
                        <div className="mt-1">
                            <input type="text" name="Last_Name" id="Last_Name" className="w-full px-4 py-2 border outline-none rounded-xl" onChange={handlechange}  required />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="text-lg">Email</label>
                        <div className="mt-1">
                            <input type="text" name="Email" id="Email" className="w-full px-4 py-2 border outline-none rounded-xl" onChange={handlechange} required />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="text-lg">Password</label>
                        <div className="mt-1">
                            <input type="Password" name="Password" id="Password" className="w-full px-4 py-2 border outline-none rounded-xl" onChange={handlechange} required />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="text-lg">Mobile number</label>
                        <div className="mt-1">
                            <input type="Mobilenumber" name="Mobilenumber" id="Mobilenumber" className="w-full px-4 py-2 border outline-none rounded-xl" onChange={handlechange} required />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="text-lg">Address</label>
                        <div className="mt-1">
                            <textarea type="text" name="Address" id="Address" className="w-full px-4 py-2 border outline-none rounded-xl" onChange={handlechange} required />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="text-lg">Street Address</label>
                        <div className="mt-1">
                            <input type="text" name="Street_Address" id="Street_Address" className="w-full px-4 py-2 border outline-none rounded-xl" onChange={handlechange} required />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="text-lg">City</label>
                        <div className="mt-1">
                            <input type="text" name="City" id="City" className="w-full px-4 py-2 border outline-none rounded-xl" onChange={handlechange} required />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="text-lg">State</label>
                        <div className="mt-1">
                            <input type="text" name="State" id="State" className="w-full px-4 py-2 border outline-none rounded-xl" onChange={handlechange} required />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="text-lg">Country</label>
                        <div className="mt-1">
                            <input type="text" name="Country" id="Country" className="w-full px-4 py-2 border outline-none rounded-xl" onChange={handlechange} required />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="text-lg">Pincode</label>
                        <div className="mt-1">
                            <input type="text" name="Zipcode" id="Zipcode" className="w-full px-4 py-2 border outline-none rounded-xl" onChange={handlechange} required />
                        </div>
                    </div>
                    </div>
                    <div className="flex items-start justify-start mt-2">
                        <button type="submit" className="inline-flex items-center justify-center px-6 py-3 text-xl font-bold leading-6 text-center text-white transition-all duration-300 ease-in-out rounded-lg my-link gap-x-3 bg-regal-blue hover:bg-gradient-to-l hover:from-regal-yellow hover:to-regal-blue">
                        {loading ? "Registering..." : "Register"}
                        </button>
                    </div>
                    <div className="mt-4">
                        <h1>Alredy registered? <Link to="/login" className="underline">Login Here</Link></h1>
                    </div>
                </form>
            </div>
        </div>
    </div>
    )
}