import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { apisendotpforgotpassword } from "../../services/apiauthentication/apiregister";

export function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
        const response = await apisendotpforgotpassword({ Email: email });
        if (response.status === "OTP Sent") {
          toast.success("OTP sent to your email!");
          navigate(`/verify-forgot-password?email=${email}`);
        } else {
          toast.error(response.status);
        }
      } catch (error) {
        console.log(error);
        toast.error("An error occurred. Please try again.");
      }
      setLoading(false);
    };
  
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="max-w-[30rem] mx-auto w-full px-4">
          <div className="p-8 bg-white border shadow-xl rounded-xl">
            <h1 className="mb-6 text-2xl font-bold text-center">Forgot Password</h1>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-lg">Email</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-4 py-2 border outline-none rounded-xl"
                  required
                />
              </div>
              <div className="flex items-center justify-center">
                <button
                  type="submit"
                  className="inline-flex items-center justify-center px-6 py-3 text-xl font-bold text-white rounded-lg bg-regal-blue"
                  disabled={loading}
                >
                  {loading ? "Sending..." : "Send OTP"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }