
import Home from "../../shared/components/Home/Home";


export default function HomePage() {

    return (
        <>
            <Home />
        </>
    )
}