import { useEffect, useState } from "react";
import ViewProduct from "../../shared/components/ViewProduct/ViewProduct";
import useCartStore from "../../shared/services/store/usecart";
import useAuth from "../../shared/services/store/useAuth";
import { useLocation, useParams } from "react-router-dom";
import { getProductById } from "../../shared/services/apigetbooks/apigetbooks";
import toast from "react-hot-toast";
import { apisavecart } from "../../shared/services/apicart/apicart";

export default function Viewproductfunction () {

    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [showShareMenu, setShowShareMenu] = useState(false);
    const location = useLocation();
    const { id } = useParams();
    const { addToCart, cart: cartItems } = useCartStore();
    const { userdetails } = useAuth();


    useEffect(() => {
        if (location.state?.product) {
            setProduct(location.state.product);
        }
    }, [location]);

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                setLoading(true);
                if (location.state?.product) {
                    setProduct(location.state.product);
                } else if (id) {
                    const data = await getProductById(id);
                    setProduct(data);
                }
            } catch (err) {
                setError(err.message);
                toast.error("Failed to load product details");
            } finally {
                setLoading(false);
            }
        };

        fetchProduct();
    }, [id, location.state]);

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="p-4 space-y-4">
                    <div className="w-16 h-16 border-4 border-t-4 border-blue-500 rounded-full animate-spin"></div>
                    {/* <p className="text-lg font-medium text-gray-600">Loading product details...</p> */}
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="p-8 text-center">
                    <h2 className="mb-4 text-2xl font-bold text-gray-800">Product Not Found</h2>
                    <p className="mb-4 text-gray-600">The product you&apos;re looking for doesn&apos;t exist or has been removed.</p>
                    <a href="/study-materials" className="px-6 py-2 text-white bg-blue-500 rounded hover:bg-blue-600">
                        Return to Study Materials
                    </a>
                </div>
            </div>
        );
    }

    const copyToClipboard = () => {
        const url = window.location.href;
        
        const fallbackCopy = () => {
            const textArea = document.createElement("textarea");
            textArea.value = url;
            
            textArea.style.position = 'fixed';
            textArea.style.left = '-999999px';
            textArea.style.top = '-999999px';
            document.body.appendChild(textArea);
            
            textArea.focus();
            textArea.select();
            
            try {
                document.execCommand('copy');
                textArea.remove();
                toast.success('Link copied to clipboard!');
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
                toast.error('Failed to copy link');
            }
            
            textArea.remove();
        };

        if (navigator.clipboard && window.isSecureContext) {
            navigator.clipboard.writeText(url)
                .then(() => {
                    toast.success('Link copied to clipboard!');
                })
                .catch(() => {
                    fallbackCopy();
                });
        } else {
            fallbackCopy();
        }
        
        setShowShareMenu(false);
    };

    const handleShare = (platform) => {
        const url = window.location.href;
        const text = `Check out ${product.Book_Name}!`;
        
        switch(platform) {
            case 'whatsapp':
                window.open(`https://wa.me/?text=${encodeURIComponent(text + ' ' + url)}`, '_blank');
                break;
            case 'facebook':
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`, '_blank');
                break;
            case 'twitter':
                window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`, '_blank');
                break;
        }
        setShowShareMenu(false);
    };

    const handleAddToCart = async () => {
        if (!userdetails) {
            toast.error("Please log in to add items to your cart!");
            return;
        }

        if (product.Quantity === 0) {
            toast.error("This item is currently out of stock!");
            return;
        }

        const cartItemsFromStore = cartItems || [];
        if (cartItemsFromStore.some(item => item.productId._id === product._id)) {
            toast.error("Product is already in your cart!");
            return;
        }

        try {
            const cartData = { 
                productId: product._id, 
                Email: userdetails.Email, 
                Quantity: 1 
            };
            await apisavecart(cartData);
            addToCart(product);
            toast.success("Product added to cart successfully!");
        } catch (error) {
            toast.error("Failed to add product to cart.");
            console.error("Error adding product to cart:", error);
        }
    };

    if (!product) {
        return  <div className="flex items-center justify-center min-h-screen">
                    <div className="w-16 h-16 border-4 border-t-4 border-blue-500 rounded-full animate-spin"></div>
                </div>;
    }

    const getStockStatus = (quantity) => {
        if (quantity === undefined || quantity === null) {
            return { text: "In Stock", color: "bg-green-100 text-green-800" };
        }
        if (quantity === 0) {
            return { text: "Out of Stock", color: "bg-red-100 text-red-800" };
        }
        if (quantity < 10) {
            return { text: "Low Stock", color: "bg-yellow-100 text-yellow-800" };
        }
        return { text: "In Stock", color: "bg-green-100 text-green-800" };
    };

    const stockStatus = getStockStatus(product.Quantity);


    return(
        <>
            <ViewProduct product={product} setThumbsSwiper={setThumbsSwiper} showShareMenu={showShareMenu} setShowShareMenu={setShowShareMenu} 
            handleAddToCart={handleAddToCart} handleShare={handleShare} stockStatus={stockStatus} 
            copyToClipboard={copyToClipboard} thumbsSwiper={thumbsSwiper} />
        </>
    )
}