/* eslint-disable react/prop-types */
import {Pagination} from "@nextui-org/pagination";
import { useMemo } from "react";
export default function Cuspagination(props) {
  const {page,rows,onPage,totallength}=props;
  const pages = useMemo(() => {
    return totallength ? Math.ceil(totallength / rows) : 0;
  }, [totallength, rows]);
  return (
    <div className="flex justify-center">
     {totallength!=0&&( <Pagination  
                    showControls
                    showShadow
                    color="secondary"
                    page={page}
                    total={pages}
                    onChange={(page) => onPage(page)} 
                    classNames={{
        prev:"bg-white border",
        next:"bg-white border",
        item: "bg-white border",
      }}/>)}
    </div>
  )
}