import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { apiupdatepassword } from "../../services/apiauthentication/apiregister";

export function ResetPassword() {
    const [passwords, setPasswords] = useState({
      password: "",
      confirmPassword: ""
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const email = new URLSearchParams(window.location.search).get("email");
  
    const handleChange = (e) => {
      setPasswords({
        ...passwords,
        [e.target.name]: e.target.value
      });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      if (passwords.password !== passwords.confirmPassword) {
        toast.error("Passwords do not match!");
        return;
      }
  
      setLoading(true);
      try {
        const response = await apiupdatepassword({
          Email: email,
          Password: passwords.password
        });
        
        if (response.status === "Sucessfully Password changed") {
          toast.success("Password updated successfully!");
          navigate("/login");
        } else {
          toast.error(response.status);
        }
      } catch (error) {
        console.log(error);
        toast.error("An error occurred. Please try again.");
      }
      setLoading(false);
    };
  
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="max-w-[30rem] mx-auto w-full px-4">
          <div className="p-8 bg-white border shadow-xl rounded-xl">
            <h1 className="mb-6 text-2xl font-bold text-center">Reset Password</h1>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-lg">New Password</label>
                <input
                  type="password"
                  name="password"
                  value={passwords.password}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border outline-none rounded-xl"
                  required
                />
              </div>
              <div className="mb-6">
                <label className="block text-lg">Confirm Password</label>
                <input
                  type="password"
                  name="confirmPassword"
                  value={passwords.confirmPassword}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border outline-none rounded-xl"
                  required
                />
              </div>
              <div className="flex items-center justify-center">
                <button
                  type="submit"
                  className="inline-flex items-center justify-center px-6 py-3 text-xl font-bold text-white rounded-lg bg-regal-blue"
                  disabled={loading}
                >
                  {loading ? "Updating..." : "Reset Password"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }