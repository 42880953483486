/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";

export default function CourseVideo(props) {
    const { videovisible, setVideoVisible, videoUrl, loading, setLoading } = props;
    const [isYouTube, setIsYouTube] = useState(false);
    const [embedUrl, setEmbedUrl] = useState("");

    useEffect(() => {
        if (videoUrl) {
            const youtubeId = extractYouTubeId(videoUrl);
            if (youtubeId) {
                setIsYouTube(true);
                setEmbedUrl(`https://www.youtube.com/embed/${youtubeId}`);
            } else {
                setIsYouTube(false);
                setEmbedUrl(videoUrl);
            }
            setLoading(false);
        }
    }, [videoUrl]);

    const extractYouTubeId = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        const match = url.match(regExp);
        return (match && match[2].length === 11) ? match[2] : null;
    };

    return (
        <Dialog
            visible={videovisible}
            onHide={() => { setVideoVisible(false) }}
            style={{ width: '50vw' }}
            breakpoints={{ '960px': '75vw', '641px': '100vw' }}
            maximizable
        >
            {loading && 'Loading...'}
            {embedUrl && (
                isYouTube ? (
                    <iframe
                        width="100%"
                        height="500"
                        src={embedUrl}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Video player"
                        onLoad={() => setLoading(false)}
                        onError={() => setLoading(false)}
                    ></iframe>
                ) : (
                    <video
                        controls
                        width="100%"
                        height="315"
                        onLoadedData={() => setLoading(false)}
                        onError={() => setLoading(false)}
                    >
                        <source src={embedUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                )
            )}
        </Dialog>
    )
}