import { useState } from "react";
import Registerform from "../../shared/components/RegisterForm/Registerform";
import { useNavigate } from "react-router-dom";
import { apiregister } from "../../shared/services/apiauthentication/apiregister";
import toast from "react-hot-toast";

export default function RegisterFormPage () {

    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
  
    const handlechange = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    };
  
    const handleRegister = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await apiregister(formData);
            if (response.message === "Successfully registered. OTP sent to your email and mobile number.") {
                toast.success(response.message);
                navigate(`/verify-otp?email=${formData.Email}`);
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            if (error.response && error.response.data) {
                toast.error(error.response.data.message);
            } else {
                toast.error("An error occurred during registration. Please try again.");
            }
        }
        setLoading(false);
    };

    return(
        <>
            <Registerform handleRegister={handleRegister} handlechange={handlechange} loading={loading} />
        </>
       
    )
}