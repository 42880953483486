import { useCallback, useEffect, useState } from "react";
import Tableheadpanel from "../../shared/components/Purchase/Tableheadpanel";
import moment from "moment-timezone";
import {apigetAllPurchases, apigetpurchaseitemsbyid, apiPurchasedownloadPDF, apiSavepurchase, updatePurchase } from "../../shared/services/apipurchase/apipurchase";
import toast from "react-hot-toast";
import { saveAs } from 'file-saver';
import Addandeditform from "../../shared/components/Purchase/AddandEditform";
import Tableview from "../../shared/components/Purchase/Tableview";
import ViewModel from "../../shared/components/Purchase/ViewModel";

export default function Purchasepage() {
    const [totalRecords, setTotalRecords] = useState(0);
    const [tabledata, setTabledata]=useState([]);
    const [colfilter, setcolFilter] = useState({});
    const [globalfilter,setglobalfilter]=useState('');
    const [page, setPage] = useState(1);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tempFilterValues, setTempFilterValues] = useState([]);
    const [Purchasedata,setPurchasedata]=useState([]);
    const [Viewdata,setViewdata]=useState({});
    const [View, setView] = useState(false);
    const [downloadingPDF, setDownloadingPDF] = useState({});
    const [formdata, setFormdata] = useState({
        products: [{}],
        Total_Quantity: 0,
        Total_Amount: 0
    });
    const [Formfunction, setFormfunction] = useState('');

    let isMounted = true;

    const getallpurchasedetails = useCallback(async ()=>{
        const res= await apigetAllPurchases({first,rows,globalfilter,colfilter});
        setTabledata(res?.resdata);
        setTotalRecords(res?.totallength);
    },[first,rows,globalfilter,colfilter]);

    useEffect(()=>{
        if(isMounted){
            getallpurchasedetails();
        }
        return(()=>isMounted = false);
    },[first,rows,globalfilter,colfilter])


    const onPage = (page) => {
        setPage(page)
        setFirst(rows *(page -1));
        setRows(rows);
    };

    const cusfilter = (field, value) => {
        setcolFilter(prev => ({ ...prev, [field]: {$in:value} }));
        setFirst(0)
    };

    const newform = () => {
        setFormdata({
            products: [{Title: "", ISBNnumber: "", Quantity: 0, UnitPrice: 0, Discount: 0, TotalPrice: 0}],
            Total_Quantity: 0,
            Total_Amount: 0,
            Order_Date: moment().format('YYYY-MM-DD')
        });
        setFormfunction("Save");
        setVisible(true);
    };

    const editfrom= async (data)=>{
        const res= await apigetpurchaseitemsbyid(data);
        setFormdata({...data,Order_Date:moment(data.Order_Date).format('YYYY-MM-DD'),products:res});
        setFormfunction("Update")
        setVisible(true)
    }


    const addRow = () => {
        const newObject = {Title: "", ISBNnumber: "", Quantity: 0, UnitPrice: 0, Discount: 0, TotalPrice: 0};
        setFormdata(prevData => ({
            ...prevData,
            products: [...prevData.products, newObject]
        }));
    };

    const handlechange = (e) => {
        setFormdata(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    };

    const handleview = async (data)=>{
        const res= await apigetpurchaseitemsbyid(data);
        setPurchasedata(res)
        setViewdata(data);
        setView(true)
    }

    const handlechangeProduct = (event, rowIndex) => {
        const { name, value } = event.target;

        setFormdata(prev => {
            const updatedProducts = [...prev.products];
            updatedProducts[rowIndex.rowIndex] = {
                ...updatedProducts[rowIndex.rowIndex],
                [name]: value
            };

            let totalQuantity = 0;
            let totalAmount = 0;

            updatedProducts.forEach(product => {
                const quantity = parseFloat(product.Quantity) || 0;
                const unitPrice = parseFloat(product.UnitPrice) || 0;
                const discount = parseFloat(product.Discount) || 0;

                totalQuantity += quantity;
                const productTotal = quantity * unitPrice * (1 - discount / 100);
                totalAmount += productTotal;

                product.TotalPrice = productTotal.toFixed(2);
            });

            return {
                ...prev,
                products: updatedProducts,
                Total_Quantity: totalQuantity,
                Total_Amount: totalAmount.toFixed(2)
            };
        });
    };

    const calculateTotals = (products) => {
        let totalQuantity = 0;
        let totalAmount = 0;

        products.forEach(product => {
            const quantity = parseFloat(product.Quantity) || 0;
            const unitPrice = parseFloat(product.UnitPrice) || 0;
            const discount = parseFloat(product.Discount) || 0;

            totalQuantity += quantity;
            const productTotal = quantity * unitPrice * (1 - discount / 100);
            totalAmount += productTotal;
        });

        return {
            Total_Quantity: totalQuantity,
            Total_Amount: totalAmount.toFixed(2)
        };
    };

    const handledeleteField = (event, rowIndex) => {
        if (formdata.products.length > 1) {
            setFormdata(prevData => {
                const updatedProducts = prevData.products
                    .filter((_, index) => index !== rowIndex.rowIndex)
                    .map((product, index) => ({
                        ...product,
                        Title: `${prevData.First_Name || ''} ${index + 1}`
                    }));

                const totals = calculateTotals(updatedProducts);

                return {
                    ...prevData,
                    products: updatedProducts,
                    ...totals
                };
            });
        }
    };

    const handlesave = async (e) => {
        e.preventDefault();
        try {
            const payload = {
                // Customer details
                First_Name: formdata.First_Name,
                Last_Name: formdata.Last_Name,
                Mobilenumber: formdata.Mobilenumber,
                Address: formdata.Address,
                City: formdata.City,
                State: formdata.State,
                Country: formdata.Country,
                Zipcode: formdata.Zipcode,
                Order_Date: formdata.Order_Date,
                // Array of products (books)
                products: formdata.products.map(product => ({
                    Book_Name: product.Book_Name,
                    ISBNnumber: product.ISBNnumber,
                    Quantity: product.Quantity,
                    UnitPrice: product.UnitPrice,
                    Discount: product.Discount,
                    TotalPrice: product.TotalPrice
                }))
            };
    
            const response = await apiSavepurchase(payload);
            
            if (response.success) {
                toast.success("Purchase order saved successfully");
                await getallpurchasedetails(); 
                setVisible(false);
            } else {
                toast.error("Failed to save purchase order: " + response.message);
            }
        } catch (error) {
            console.error("Error saving purchase order:", error);
            toast.error("An error occurred while saving the purchase order. Please try again.");
        }
    };

    const handleupdate = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            
            // Prepare the payload matching the API structure
            const payload = {
                purchaseData: {
                    Purchase_id: formdata.Purchase_id,
                    First_Name: formdata.First_Name,
                    Last_Name: formdata.Last_Name,
                    Email: formdata.Email,
                    Mobilenumber: formdata.Mobilenumber,
                    Address: formdata.Address,
                    City: formdata.City,
                    State: formdata.State,
                    Country: formdata.Country,
                    Zipcode: formdata.Pincode,
                    Order_Date: formdata.Order_Date,
                },
                products: formdata.products.map(product => ({
                    Book_Name: product.Book_Name,
                    ISBNnumber: product.ISBNnumber,
                    Quantity: parseFloat(product.Quantity) || 0,
                    UnitPrice: parseFloat(product.UnitPrice) || 0,
                    Discount: parseFloat(product.Discount) || 0,
                    TotalPrice: parseFloat(product.TotalPrice) || 0
                }))
            };
    
            const response = await updatePurchase(payload);
            
            if (response.success) {
                toast.success("Purchase updated successfully");
                await getallpurchasedetails(); 
                setVisible(false);
            } else {
                toast.error("Failed to update purchase: " + response.message);
            }
        } catch (error) {
            console.error("Error updating purchase:", error);
            toast.error("An error occurred while updating the purchase. Please try again.");
        } finally {
            setLoading(false);
        }
    };



    const downloadPDF = async (Purchase_id) => {
        setDownloadingPDF(prev => ({ ...prev, [Purchase_id]: true }));
        try {
            var resData = await apiPurchasedownloadPDF(Purchase_id);
            const pdfBlob = new Blob([resData], { type: 'application/pdf' });
            const pdfFileName = `${Purchase_id}.pdf`;
            saveAs(pdfBlob, pdfFileName);
        } catch (error) {
            console.error("Error downloading PDF:", error);
            toast.error("Failed to download PDF");
        } finally {
            setDownloadingPDF(prev => ({ ...prev, [Purchase_id]: false }));
        }
    }
    
    

    return (
        <div>
            <Tableheadpanel newform={newform} setglobalfilter={setglobalfilter}/>
            <Tableview tabledata={tabledata} totalRecords={totalRecords} first={first}  handleview={handleview} downloadPDF={downloadPDF} downloadingPDF={downloadingPDF}
                    cusfilter={cusfilter} onPage={onPage} page={page} editfrom={editfrom} tempFilterValues={tempFilterValues} setTempFilterValues={setTempFilterValues} />
            <Addandeditform 
                visible={visible} 
                setVisible={setVisible} 
                Formfunction={Formfunction} 
                addRow={addRow} 
                handlechange={handlechange}
                handlechangeProduct={handlechangeProduct}
                formdata={formdata}
                handledeleteField={handledeleteField}
                handlesave={handlesave}
                handleupdate={handleupdate}
                loading={loading}
            />
            <ViewModel View={View} setView={setView} loading={loading} Viewdata={Viewdata} setViewdata={setViewdata} Purchasedata={Purchasedata} />
        </div>
    );
}
