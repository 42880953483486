/* eslint-disable react/prop-types */
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';

export default function ViewModel(props) {

    const { View, setView,Viewdata,Purchasedata } = props;

    const sno = (rowData,rowIndex) => {
        return (
            <div>
                {rowIndex['rowIndex']+1}
            </div>
        )
    }

    let columns = [
        {field: 'Book_Name', header: 'Book Title', width:'150px'},
        {field: 'ISBNnumber', header: 'ISBN Number', width:'150px'},
        {field: 'UnitPrice', header: 'Unit Price', width:'150px'},
        {field: 'Quantity', header: 'Quantity', width:'150px'},
        {field: 'Discount', header: 'Discount', width:'150px'},
        {field: 'TotalPrice', header: 'Total Price', width:'150px'},
    
    ];


    return (
        <Dialog header={Viewdata.Purchase_id} visible={View}  onHide={() => setView(false)} className="w-full">
                <DataTable value={Purchasedata} scrollable scrollHeight="680px" className='!text-sm' stateStorage="session" stateKey="dt-state-demo-local" > 
                    <Column header="S.No" body={sno} style={{ minWidth: '50px' }}></Column>
                    {columns.map((col, i) => (
                        <Column key={i} field={col.field} header={col.header} style={{minWidth:col.width}} />
                    ))}
                </DataTable>
        </Dialog>
    )
}