import Customtable from "../shared/components/Courses/CustomTable";
import TableheadPanel from "../shared/components/Courses/Tableheadpanel";
import useForm from "../hooks/useForm";
import Addaneditform from "../shared/components/Courses/Addaneditform";
import { apideletecourse, apigetallcourse, apisavecourse, apiupdatecourse } from "../shared/services/courses/apicourses";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import toast from "react-hot-toast";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import Pdfview from "../shared/components/Courses/Pdfview";
import Cuspagination from "../shared/components/Courses/Cuspagination";
import useTable from "../hooks/useTable";
import CourseVideo from "../shared/components/Courses/CourseVideo";
import apiurl from "../shared/services/apiendpoint/apiendpoint";
import AddCourseName from "../shared/components/Courses/AddCourseName";

export default function Courses() {

    const { page, first, rows, globalFilter, setGlobalFilter, onPage } = useTable();
    const { formdata, handlechange, visible, setVisible, openform, editform, resetForm, openCourseNameForm, courseNameVisible, setCourseNameVisible } = useForm({});
    const [pdfvisible, setpdfvisible] = useState(false);
    const [videovisible, setVideoVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [pdfdata, setpdfdata] = useState({});
    const queryClient = useQueryClient();
    const { data } = useQuery({ queryKey: ['courseData', page, rows, globalFilter], queryFn: () => apigetallcourse({ first, rows, globalFilter }) })
    const handlesave = async (e) => {
        e.preventDefault();
        try {
            const res = await apisavecourse(formdata);
            console.log(res);
            res.message === "Successfully saved" 
                ? handlesuccess(res) 
                : toast.error(res.message);
        } catch (error) {
            toast.error("Failed to save course");
        }
    }
    const handleupdate = async (e) => {
        e.preventDefault();
        const { _id, ...otherData } = formdata;
        const formDataToSend = new FormData();
    
        // Append all text fields
        Object.keys(otherData).forEach(key => {
            if (key !== 'Course_Pdf') {
                formDataToSend.append(key, otherData[key]);
            }
        });
    
        // Handle PDF files specifically
        if (otherData.Course_Pdf && otherData.Course_Pdf.length > 0) {
            // Differentiate between new files and existing paths
            otherData.Course_Pdf.forEach((file, index) => {
                if (file instanceof File) {
                    // New file
                    formDataToSend.append('Course_Pdf', file);
                } else if (typeof file === 'string') {
                    // Existing file path
                    formDataToSend.append('existingPdfs', file);
                }
            });
        }
    
        try {
            const res = await apiupdatecourse(_id, formDataToSend);
            if (res.message === "Successfully updated") {
                handlesuccess(res);
            } else {
                toast.error(res.message);
            }
        } catch (error) {
            console.error("Error updating course:", error);
            toast.error("An error occurred while updating the course");
        }
    };
    const handledelete = async (_id) => {
        const res = await apideletecourse(_id);
        res.message == "Successfully deleted" ? handlesuccess(res) : toast.error(res.message);
    }
    const handlesuccess = (res) => {
        toast.success(res.message)
        queryClient.invalidateQueries('courseData');
        resetForm();
    }
    const confirm2 = (_id) => {
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger bg-red-500 ml-2 text-white py-2 px-3',
            rejectClassName: 'ml-2  py-2 px-3',
            accept: () => { handledelete(_id) }
        });
    };
    const handlepdfview = async (_id, pdfIndex) => {
        setpdfdata({ _id, pdfIndex, isloading: false });
        setpdfvisible(true)
    }
    const handleVideoView = (url) => {
        const isAbsoluteUrl = url.startsWith('http://') || url.startsWith('https://');
        const fullUrl = isAbsoluteUrl ? url : `${apiurl()}${url}`;
        setVideoUrl(fullUrl);
        setVideoVisible(true);
        setLoading(true);
    };
    return (
        <>
            <TableheadPanel openform={openform} setGlobalFilter={setGlobalFilter} openCourseNameForm={openCourseNameForm} />
            <Customtable data={data} editform={editform} confirm2={confirm2} handlepdfview={handlepdfview} handleVideoView={handleVideoView} />
            {data?.totallength > 0 && (<Cuspagination page={page} rows={rows} onPage={onPage} totallength={data?.totallength} />)}
            <Addaneditform visible={visible} setVisible={setVisible} formdata={formdata}
                handlechange={handlechange} handlesave={handlesave} handleupdate={handleupdate} />
            <Pdfview pdfvisible={pdfvisible} setpdfvisible={setpdfvisible} pdfdata={pdfdata} />
            <CourseVideo videovisible={videovisible} setVideoVisible={setVideoVisible} videoUrl={videoUrl} loading={loading} setLoading={setLoading} />
            <AddCourseName courseNameVisible={courseNameVisible} setCourseNameVisible={setCourseNameVisible} />
            <ConfirmDialog />
        </>
    )
}