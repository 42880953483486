/* eslint-disable react/prop-types */
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/free-mode";
import { Navigation, Thumbs, FreeMode } from "swiper/modules";
import apiurl from "../../../admin/shared/services/apiendpoint/apiendpoint";
import { Share2, ShoppingCart, Box, Check, Truck, Copy } from "lucide-react";
import ViewProductSEO from "./ViewProductSEO";

export default function ViewProduct({product, setThumbsSwiper, showShareMenu, setShowShareMenu, handleAddToCart, handleShare, stockStatus, copyToClipboard, thumbsSwiper}) {

    return (
        <>
            <ViewProductSEO  product={product} />
        <div className="min-h-screen bg-gray-50">
            <div className="container px-4 py-8 mx-auto">
                <nav className="mb-8 text-sm">
                    <ol className="flex items-center space-x-2">
                        <li><a href="/" className="text-gray-500 hover:text-gray-700">Home</a></li>
                        <li className="text-gray-500">/</li>
                        <li><a href="/study-materials" className="text-gray-500 hover:text-gray-700">Study Materials</a></li>
                        <li className="text-gray-500">/</li>
                        <li className="font-medium text-gray-900">{product?.Book_Name}</li>
                    </ol>
                </nav>

                <div className="grid grid-cols-1 gap-8 bg-white rounded-lg shadow-lg md:grid-cols-2">
                    <div className="p-6">
                        <div className="mb-4">
                            <Swiper style={{ '--swiper-navigation-color': '#000', '--swiper-pagination-color': '#000'}} navigation={true}
                                thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                                modules={[Navigation, Thumbs]} className="w-full h-[400px] rounded-lg" loop={true}
                            >
                                {product?.Book_image.map((img, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="flex items-center justify-center w-full h-full">
                                            <img src={`${apiurl()}/${img}`} alt={`Product image ${index + 1}`} className="object-contain w-full h-full" />
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                        
                        <Swiper onSwiper={setThumbsSwiper} spaceBetween={5} slidesPerView={6} freeMode={true} watchSlidesProgress={true} modules={[FreeMode, Navigation, Thumbs]} 
                            className="h-full" loop={true}
                        >
                            {product?.Book_image.map((img, index) => (
                                <SwiperSlide key={index}>
                                    <div className="cursor-pointer">
                                        <img src={`${apiurl()}/${img}`} alt={`Thumbnail ${index + 1}`} className="object-cover w-[100px] h-[100px] border rounded hover:border-gray-400" />
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>

                    <div className="p-6 space-y-6">
                        <div className="flex items-center justify-between">
                            <h1 className="text-3xl font-bold text-gray-900">{product?.Book_Name}</h1>
                            <div className="flex items-center space-x-4">
                                <div className="relative">
                                    <button  onClick={() => setShowShareMenu(!showShareMenu)}className="p-2 text-gray-500 transition-colors rounded-full hover:bg-gray-100" >
                                        <Share2 />
                                    </button>
                                    {showShareMenu && (
                                        <div className="absolute right-0 z-10 w-48 mt-2 bg-white rounded-md shadow-lg">
                                            <div className="py-1">
                                                <button onClick={copyToClipboard} className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" >
                                                    <Copy className="w-4 h-4 mr-3" />
                                                    Copy Link
                                                </button>
                                                <button onClick={() => handleShare('whatsapp')} className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                    WhatsApp
                                                </button>
                                                <button onClick={() => handleShare('facebook')} className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                    Facebook
                                                </button>
                                                <button onClick={() => handleShare('twitter')} className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                    Twitter
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="py-4 border-t border-b">
                            <div className="flex items-baseline space-x-4">
                                <span className="text-3xl font-bold text-gray-900">₹{product?.Sale_Price}</span>
                                <span className="text-lg text-gray-500 line-through">₹{product?.Regular_Price}</span>
                                <span className="px-3 py-1 text-sm font-semibold text-white bg-green-500 rounded-full">
                                    {product?.Discount}% OFF
                                </span>
                            </div>
                        </div>

                        <div className={`inline-flex items-center px-4 py-2 space-x-2 text-sm font-medium rounded-full ${stockStatus?.color}`}>
                            <Box size={16} />
                            <span>{stockStatus?.text}</span>
                        </div>

                        <div className="space-y-4">
                            {/* Product Features/Highlights */}
                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                                <div className="flex items-center space-x-2 text-sm text-gray-600">
                                    <Check className="text-green-500" size={20} />
                                    <span>High-quality study material</span>
                                </div>
                                <div className="flex items-center space-x-2 text-sm text-gray-600">
                                    <Check className="text-green-500" size={20} />
                                    <span>Expert-curated content</span>
                                </div>
                                <div className="flex items-center space-x-2 text-sm text-gray-600">
                                    <Truck className="text-green-500" size={20} />
                                    <span>Fast delivery</span>
                                </div>
                                <div className="flex items-center space-x-2 text-sm text-gray-600">
                                    <Check className="text-green-500" size={20} />
                                    <span>Latest edition</span>
                                </div>
                            </div>

                            {/* Add to Cart Button */}
                            <button onClick={handleAddToCart} disabled={product?.Quantity === 0}
                                className={`flex items-center justify-center w-full px-6 py-4 space-x-2 text-white transition-colors rounded-lg ${
                                    product?.Quantity === 0 
                                    ? 'bg-gray-400 cursor-not-allowed' 
                                    : 'bg-regal-yellow hover:bg-yellow-600'
                                }`}
                            >
                                <ShoppingCart size={20} />
                                <span>{product?.Quantity === 0 ? 'Out of Stock' : 'Add to Cart'}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}