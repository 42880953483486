import axios from "axios";
import apiurl from "../apiendpoint/apiendpoint";
import { gettoken } from "../../../../shared/services/token/token";

export const apisaveclasses = async (datas) => {
    try {
        const token = gettoken();
        const res = await axios.post(`${apiurl()}/classes/apisaveclasses`, datas, { headers: { "Authorization": `Bearer ${token}`, "Content-Type": "application/json" }});

        return res.data;
    } catch (err) {
        console.log(err);
    }
};



export const apigetallclasses = async (params) => {
    try {
        const token = gettoken();
        const res = await axios.get(`${apiurl()}/classes/apigetallclasses`,{params:params,headers: { "Authorization": `Bearer ${token}` }});
        return res.data;
    } catch (err) {
        console.log(err);
    }
};


export const apideleteclasses = async (_id) => {
    try {
        const token = gettoken();
        const res = await axios.delete(`${apiurl()}/classes/apideleteclasses/${_id}`,{headers: { "Authorization": `Bearer ${token}` }});
        return res.data;
    } catch (err) {
        console.log(err);
    }
};

export const apiupdateclasses = async (_id,datas) => {
    try {
        const token = gettoken();
        const res = await axios.put(`${apiurl()}/classes/apiupdateclasses/${_id}`, datas,{headers: { "Authorization": `Bearer ${token}` }});
        return res.data;
    } catch (err) {
        console.log(err);
    }
};