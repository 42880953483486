/* eslint-disable react/prop-types */
import { useState } from "react";
import { Dialog } from "primereact/dialog";
import * as XLSX from "xlsx";
import toast from "react-hot-toast";
import { apiBulkSaveStudApplication } from "../../../../shared/services/apistudapplication/apistudApplication";
import { apigetallapplications } from "../../services/apiapplicationreceived/apireceivedapplications";
import useTable from "../../../hooks/useTable";


export default function BulkUpload(props) {
    const { visibleBulkModal, setVisibleBulkModal } = props;
    const [excelData, setExcelData] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [uploadResults, setUploadResults] = useState(null);
    const {first, rows, globalFilter} = useTable();


    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const data = e.target.result;
                    const workbook = XLSX.read(data, { type: "binary" });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const jsonData = XLSX.utils.sheet_to_json(worksheet);
                    
                    const requiredFields = ['Email', 'Full_name']; 
                    const hasAllFields = jsonData.every(row => 
                        requiredFields.every(field => row[field])
                    );

                    if (!hasAllFields) {
                        toast.error('Excel file is missing required fields')
                        return;
                    }

                    setExcelData(jsonData);
                } catch (error) {
                    toast.error('Error reading Excel file')

                }
            };
            reader.readAsBinaryString(file);
        }
    };

    const handleSave = async () => {
        try {
            setIsSaving(true);
            const processedData = excelData.map(row => {
                const cleanRow = {};
                for (const [key, value] of Object.entries(row)) {
                    if (value != null) {
                        cleanRow[key] = value;
                    }
                }
                return cleanRow;
            });
    
            const response = await apiBulkSaveStudApplication(processedData);
    
            if (response.summary.successful > 0) {
                toast.success(`Successfully processed ${response.summary.successful} out of ${response.summary.total} applications`);
            }
    
            if (response.summary.failed > 0) {
                toast.error(`${response.summary.failed} applications failed to process`);
            }
    
            setUploadResults(response.results);
    
            if (response.summary.successful > 0) {
                setExcelData([]);
                await apigetallapplications({ first, rows, globalFilter });
            }
        } catch (error) {
            console.error("Error in bulk upload:", error);
            toast.error(error.response?.data?.message || 'An error occurred during bulk upload');
        } finally {
            setIsSaving(false);
        }
    };
    

    return (
        <Dialog
            header="Bulk Upload"
            visible={visibleBulkModal}
            onHide={() => {
                setVisibleBulkModal(false);
                setExcelData([]);
                setUploadResults(null);
            }}
            style={{ width: "75vw" }}
            breakpoints={{ "960px": "90vw", "641px": "100vw" }}
            maximizable
        >
            
            <div className="p-4">
                <input
                    type="file"
                    accept=".xlsx, .xls"
                    onChange={handleFileUpload}
                    className="mb-4"
                />

                {excelData.length > 0 && (
                    <>
                        <div className="overflow-auto max-h-96">
                            <table className="min-w-full divide-y divide-gray-200 rounded-xl">
                                <thead className="sticky top-0 bg-gray-50">
                                    <tr>
                                        {Object.keys(excelData[0]).map((key, index) => (
                                            <th
                                                key={index}
                                                className="px-6 py-3 text-sm font-medium text-left text-gray-500"
                                            >
                                                {key}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {excelData.map((row, rowIndex) => (
                                        <tr key={rowIndex}>
                                            {Object.values(row).map((cell, cellIndex) => (
                                                <td
                                                    key={cellIndex}
                                                    className="px-6 py-4 text-sm text-gray-500"
                                                >
                                                    {cell}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="flex items-center justify-between mt-4">
                            <span className="text-sm text-gray-500">
                                {excelData.length} records loaded
                            </span>
                            <button
                                onClick={handleSave}
                                className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600 disabled:bg-blue-300"
                                disabled={isSaving}
                            >
                                {isSaving ? "Processing..." : "Upload Applications"}
                            </button>
                        </div>
                    </>
                )}

                {uploadResults && (
                    <div className="mt-4">
                        <h3 className="mb-2 text-lg font-semibold">Upload Results</h3>
                        {uploadResults.failed.length > 0 && (
                            <div className="mt-2">
                                <h4 className="font-medium text-red-600 text-md">Failed Uploads:</h4>
                                <ul className="pl-5 list-disc">
                                    {uploadResults.failed.map((fail, index) => (
                                        <li key={index} className="text-sm text-red-500">
                                            {fail.email}: {fail.reason}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </Dialog>
    );
}