import { useState, useCallback, useEffect } from "react";
import useAuth from "../../shared/services/store/useAuth";
import { getOrderdetails } from "../../shared/services/apiorder/apiorder";
import { PackageX } from "lucide-react";
import { Tooltip } from "@nextui-org/react";
import useCartStore from "../../shared/services/store/usecart";
import Cartsidebar from "../../shared/components/CheckoutandCart/Cartsidebar";

export default function MyOrders() {
  const [orderDetails, setOrderDetails] = useState([]);
  const { userdetails } = useAuth();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const {cart} = useCartStore();

  let isMounted = true;

  const fetchOrderDetails = useCallback(async () => {
    try {
      const res = await getOrderdetails({ Email: userdetails?.Email });
      setOrderDetails(res.resdata);
    } catch (err) {
      console.error("Error fetching order details:", err);
    }
  }, [userdetails?.Email]);

  useEffect(() => {
    if (isMounted) {
      fetchOrderDetails();
    }
    return () => (isMounted = false);
  }, [fetchOrderDetails]);

  useEffect(() => {
    const toTopButton = document.getElementById("to-top-button");

    const handleScroll = () => {
      if (
        document.body.scrollTop > 500 ||
        document.documentElement.scrollTop > 500
      ) {
        toTopButton.classList.remove("hidden");
      } else {
        toTopButton.classList.add("hidden");
      }
    };

    const handleClick = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    if (toTopButton) {
      window.addEventListener("scroll", handleScroll);
      toTopButton.addEventListener("click", handleClick);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (toTopButton) {
        toTopButton.removeEventListener("click", handleClick);
      }
    };
  }, []);

  const toggleSidebar = () => { setIsSidebarOpen(!isSidebarOpen); };

  return (
    <>
    <div className="max-w-5xl px-4 py-8 mx-auto">
      <h1 className="mb-6 text-3xl font-bold">My Orders</h1>
      
      {orderDetails.length > 0 ? (
        <div className="space-y-6">
          {orderDetails.map((order) => (
            <div key={order._id} className="p-6 bg-white rounded-lg shadow-md">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-xl font-semibold">Order ID: {order.Order_id}</h2>
                <span className={`px-3 py-1 rounded-full text-sm font-medium ${
                  order.Payment_Status === 'Paid' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                }`}>
                  {order.Payment_Status}
                </span>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-gray-600">Order Date:</p>
                  <p className="font-medium">{new Date(order.Order_Date).toLocaleDateString()}</p>
                </div>
                <div>
                  <p className="text-gray-600">Total Amount:</p>
                  <p className="font-medium">₹{order.Total_Amount}</p>
                </div>
                <div>
                  <p className="text-gray-600">Billing Name:</p>
                  <p className="font-medium">{order.Billing_Name}</p>
                </div>
                <div>
                  <p className="text-gray-600">Email:</p>
                  <p className="font-medium">{order.Email}</p>
                </div>
                <div className="">
                  <p className="text-gray-600">Delivery Address:</p>
                  <p className="font-medium">{order.Delivery_Address}</p>
                </div>
                <div>
                  <p className="text-gray-600">Order Status:</p>
                  <p className="font-medium">{order.Order_Status}</p>
                </div>
                <div>
                  <p className="text-gray-600">Invoice ID:</p>
                  <p className="font-medium">{order.Invoice_ID}</p>
                </div>
                <div>
                  <p className="text-gray-600">Payment ID:</p>
                  <p className="font-medium">{order.Payment_Id}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center p-8 bg-white rounded-lg shadow-md">
          <PackageX className="w-16 h-16 mb-4 text-gray-400" />
          <h3 className="mb-2 text-xl font-semibold text-gray-900">No Orders Found</h3>
          <p className="text-center text-gray-600">You haven&apos;t placed any orders yet. Start shopping to see your orders here!</p>
        </div>
      )}
    </div>
      <div className="fixed z-50 flex flex-col items-end space-y-8 bottom-24 right-5">
      <div className="relative" onClick={toggleSidebar}>
          <img src="/images/shopping-cart.png" alt="Cart" className="cursor-pointer w-14 h-14" />
          <span className="absolute flex items-center justify-center w-6 h-6 text-xs text-white bg-red-600 rounded-full -top-2 -right-2">
              {cart.length}
          </span>
      </div>

      <a href="https://wa.me/9047127777?text=Hello%20I%20want%20to%20enquire%20about%20your%20services" target="_blank" rel="noopener noreferrer">
          <Tooltip color="success" content="Contact on WhatsApp Now!" placement="left" showArrow={true}>
              <img src="/images/whatsapp.png" alt="WhatsApp" className="w-14 h-14 animate-bounce-up-down" />
          </Tooltip>
      </a>
  </div>
  <button
        id="to-top-button"
        title="Go To Top"
        className="fixed z-50 hidden p-4 text-lg font-semibold text-white transition-colors duration-300 border-0 rounded-full shadow-md bottom-5 right-5 w-14 h-14 bg-regal-blue hover:bg-regal-yellow"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-6 h-6"
        >
          <path d="M12 4l8 8h-6v8h-4v-8H4l8-8z" />
        </svg>
      </button>
  <Cartsidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} />

  </>
  );
}