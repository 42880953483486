import axios from "axios";
import apiurl from "../../../../shared/services/apiendpoint/apiendpoint";
import { gettoken } from "../../../../shared/services/token/token";

export const apiSavepurchase = async (payload) => {
    try {
        const token = gettoken();
        const res = await axios.post(
            `${apiurl()}/purchase/savepurchaseitems`,
            payload,
            {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            }
        );
        return res.data;
    } catch (err) {
        console.log(err);
        throw err;
    }
};


export const apigetallbooksnames = async (params) => {
    try {
        const token = gettoken();
        const res = await axios.get(`${apiurl()}/purchase/getallbooknames`,{params:params,headers: { "Authorization": `Bearer ${token}` }});
        return res.data;
    } catch (err) {
        console.log(err);
    }
};

export const apigetAllPurchases = async (params) => {
        const token = gettoken();
        const res = await axios.get(`${apiurl()}/purchase/getallpurchase`,{params:params,headers: { "Authorization": `Bearer ${token}` }});
        return res.data;
};

export const apigetpurchaseitemsbyid = async(datas)=>{
    var res = await axios.get(`${apiurl()}/purchase/apigetpurchaseitemsbyid`,{params:{Purchase_id:datas?.Purchase_id}, headers: {"Authorization" : `Bearer ${gettoken()}`}});
    return res.data;
 }

 export const updatePurchase = async(datas)=>{
    var res = await axios.put(`${apiurl()}/purchase/apiupdatepurchase`,datas,{params:{_id:datas?._id}, headers: {"Authorization" : `Bearer ${gettoken()}`}});
    return res.data;
 }

 export const getPurchaseFilterOptions = async(data)=>{
    var res= await axios.post(`${apiurl()}/purchase/getpurchasefilteroptions`,{field:data},{headers: {"Authorization" : `Bearer ${gettoken()}`}});
    return res.data;
}

export const apiPurchasedownloadPDF = async(datas)=>{
    var res = await axios.post(`${apiurl()}/purchase/downloadPDF`,{Purchase_id:datas},{ responseType:'blob', headers: {"Authorization" : `Bearer ${gettoken()}`}});
    return res.data;
 }
