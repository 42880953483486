/* eslint-disable react/prop-types */
import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { apisavecourseName } from "../../services/courses/apicourses";
import toast from "react-hot-toast";

export default function AddCourseName (props) {

    const { courseNameVisible, setCourseNameVisible } = props;

    const [courseName, setCourseName] = useState("");

    const handleChange = (e) => {
        setCourseName(e.target.value);
    };

    const handleSave = async (e) => {
        e.preventDefault();
        const data = { courseName };
        const response = await apisavecourseName(data);
        if (response.message === "Successfully saved") {
            toast.success("Course name saved successfully.");
            setCourseNameVisible(false);
            setCourseName("");
        } else {
            toast.error("Failed to save course name. Please try again.");
        }
    };

    return(
        <>
            <Dialog header="Add Course Name Form" visible={courseNameVisible} onHide={() => { if (!courseNameVisible) return; setCourseNameVisible(false); }} style={{ width: '30vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }} maximizable
            >
                <form onSubmit={handleSave}>
                    <div>
                        <div>
                            <label>Course Name</label>
                        </div>
                        <input type="text" name="courseName" value={courseName} className="w-full px-4 py-2 mt-1 border outline-none rounded-xl" onChange={handleChange} required/>
                    </div>
                    <button type="submit" className="p-2 px-4 mt-5 text-white bg-secondary rounded-xl" > 
                        <i className="mr-1 fi fi-br-check"></i>Save
                    </button>
                </form>
            </Dialog>
        </>
    );
}
