import { useState } from 'react';
const useTable = () => {
    const [page, setPage] = useState(1);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [globalFilter, setGlobalFilter] = useState('');
    const onPage = (page) => {
        setPage(page)
        setFirst(rows *(page -1));
        setRows(rows);
    };
    const resetTable = () =>{setPage(1);setFirst(0);setRows(10);setGlobalFilter('')};
    return {page,first,rows,globalFilter,setGlobalFilter,onPage,resetTable};
};
export default useTable;