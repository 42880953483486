import axios from "axios";
import { gettoken } from "../../../../shared/services/token/token";
import apiurl from "../apiendpoint/apiendpoint";

export const apigetallstuddashcourse = async (params) => { 
    try {
        const token = gettoken();
        const res = await axios.get(`${apiurl()}/studentDashoard/apigetstuddashcourse`, { 
            params: params, 
            headers: { "Authorization": `Bearer ${token}` }
        });
        return res.data;
    } catch (err) {
        console.log(err);
    }
};
