import { useCallback, useEffect, useState } from 'react';
import { Users, Package, AlertCircle, CheckCircle, XCircle, BriefcaseBusiness, Clock } from 'lucide-react';
import { getalldashCustomers, getalldashorders, getalldashproducts } from "../../services/apiDashboard/apidashboard";
import useAuth from "../../../../shared/services/store/useAuth";

export default function Dashboard() {
  const { userdetails } = useAuth();
  const displayName = userdetails?.Role === "Admin" ? "Admin" : "";
  const [customerCount, setCustomerCount] = useState(0);
  const [products, setProducts] = useState([]);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [productloading, setProductLoading] = useState(true);
  const [ordersloading, setOrdersLoading] = useState(true);

  let isMounted = true;

  const fetchCustomers = useCallback(async() => {
    try {
      const response = await getalldashCustomers({});
      setCustomerCount(response.count || 0);
    } catch (error) {
      console.error("Error fetching customers:", error);
    } finally {
      setLoading(false);
    }
  },[])

  const fetchproducts = useCallback(async() => {
    try {
      const response = await getalldashproducts({});
      setProducts(response.resdata || []);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setProductLoading(false);
    }
  },[])

  const fetchOrders = useCallback(async() => {
    try {
      const response = await getalldashorders({});
      setOrders(response.resdata || []);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setOrdersLoading(false);
    }
  },[])

  useEffect(() => {
    if (isMounted) {
      fetchCustomers();
      fetchproducts();
      fetchOrders();
    }
    return () => (isMounted = false);
  }, [fetchCustomers,fetchproducts,fetchOrders]);
   
    const getOrderStatusInfo = (status, paymentStatus) => {
      if (status === "Confirmed" && paymentStatus === "Paid") {
        return {
          icon: CheckCircle,
          color: "text-green-600 bg-green-100",
          label: "Confirmed"
        };
      } else if (status === "Payment Pending" || paymentStatus === "Not Paid") {
        return {
          icon: Clock,
          color: "text-yellow-600 bg-yellow-100",
          label: "Payment Pending"
        };
      } else {
        return {
          icon: AlertCircle,
          color: "text-red-600 bg-red-100",
          label: status
        };
      }
    };

  const getStockStatus = (quantity) => {
    if (quantity === 0) return { status: 'Out of Stock', color: 'text-red-600 bg-red-100', icon: XCircle };
    if (quantity < 10) return { status: 'Low Stock', color: 'text-yellow-600 bg-yellow-100', icon: AlertCircle };
    return { status: 'In Stock', color: 'text-green-600 bg-green-100', icon: CheckCircle };
  };

  return (
    <div className="space-y-6">
      <div className="shadow-lg rounded-xl bg-regal-blue bg-gradient-to-l from-regal-yellow to-regal-blue">
        <div className="p-6">
          <h1 className="text-2xl font-medium text-center text-white md:text-3xl lg:text-4xl">
            Welcome {displayName}
          </h1>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4">
        {/* Customers Card */}
        <div className="bg-white rounded-xl shadow-lg p-6 transition-transform hover:translate-y-[-4px]">
          <div className="flex items-center justify-between mb-4">
            <div className="p-3 bg-blue-100 rounded-lg">
              <Users className="w-6 h-6 text-blue-600" />
            </div>
          </div>
          <h2 className="mb-2 text-lg font-medium text-gray-800">
            Total Customers
          </h2>
          <div className="flex items-center">
            {loading ? (
              <div className="w-24 h-8 bg-gray-200 rounded animate-pulse" />
            ) : (
              <span className="text-3xl font-bold text-gray-900">
                {customerCount.toLocaleString()}
              </span>
            )}
          </div>
        </div>

        {/* Total Products Card */}
        <div className="bg-white rounded-xl shadow-lg p-6 transition-transform hover:translate-y-[-4px]">
          <div className="flex items-center justify-between mb-4">
            <div className="p-3 bg-purple-100 rounded-lg">
              <Package className="w-6 h-6 text-purple-600" />
            </div>
          </div>
          <h2 className="mb-2 text-lg font-medium text-gray-800">
            Total Products
          </h2>
          <div className="flex items-center">
            {productloading ? (
              <div className="w-24 h-8 bg-gray-200 rounded animate-pulse" />
            ) : (
              <span className="text-3xl font-bold text-gray-900">
                {products.length}
              </span>
            )}
          </div>
        </div>

        {/* Total Orders Card */}

        <div className="bg-white rounded-xl shadow-lg p-6 transition-transform hover:translate-y-[-4px]">
          <div className="flex items-center justify-between mb-4">
            <div className="p-3 bg-yellow-100 rounded-lg">
              <BriefcaseBusiness   className="w-6 h-6 text-yellow-600" />
            </div>
          </div>
          <h2 className="mb-2 text-lg font-medium text-gray-800">
            Total Orders
          </h2>
          <div className="flex items-center">
            {ordersloading ? (
              <div className="w-24 h-8 bg-gray-200 rounded animate-pulse" />
            ) : (
              <span className="text-3xl font-bold text-gray-900">
                {orders.length}
              </span>
            )}
          </div>
        </div>
      </div>

      {/* Products Table */}
      <div className='grid grid-cols-1 gap-5 lg:grid-cols-2'>
      <div className="p-6 bg-white shadow-lg rounded-xl lg:h-[50vh] overflow-y-scroll">
        <h2 className="mb-4 text-xl font-semibold">Product Stock Status</h2>
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="bg-gray-50">
                <th className="px-6 py-3 text-sm font-medium text-left text-gray-500">Product Name</th>
                <th className="px-6 py-3 text-sm font-medium text-left text-gray-500">Quantity</th>
                <th className="px-6 py-3 text-sm font-medium text-left text-gray-500">Status</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {productloading ? (
                <tr>
                  <td colSpan="3" className="px-6 py-4 text-center">
                    Loading...
                  </td>
                </tr>
              ) : (
                products.map((product) => {
                  const stockStatus = getStockStatus(product.Quantity);
                  const StatusIcon = stockStatus.icon;
                  
                  return (
                    <tr key={product._id} className="hover:bg-gray-50">
                      <td className="px-6 py-4 text-sm text-gray-900">
                        {product.Book_Name}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-900">
                        {product.Quantity}
                      </td>
                      <td className="px-6 py-4">
                        <div className="flex items-center">
                          <StatusIcon className={`w-4 h-4 mr-2 ${stockStatus.color.split(' ')[0]}`} />
                          <span className={`px-3 py-1 text-sm rounded-full ${stockStatus.color}`}>
                            {stockStatus.status}
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="p-6 bg-white shadow-lg rounded-xl lg:h-[50vh] overflow-y-scroll">
      <h2 className="mb-4 text-xl font-semibold">Recent Orders</h2>
      <div className="overflow-x-auto">
        <table className="w-full min-w-[800px]">
          <thead>
            <tr className="bg-gray-50">
              <th className="px-6 py-3 text-sm font-medium text-left text-gray-500">Order ID</th>
              <th className="px-6 py-3 text-sm font-medium text-left text-gray-500">Status</th>
              <th className="px-6 py-3 text-sm font-medium text-left text-gray-500">Payment Status</th>
              <th className="px-6 py-3 text-sm font-medium text-left text-gray-500">Name</th>
              <th className="px-6 py-3 text-sm font-medium text-left text-gray-500">City</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {orders.map((order) => {
              const statusInfo = getOrderStatusInfo(order.Order_Status, order.Payment_Status);
              const StatusIcon = statusInfo.icon;
              
              return (
                <tr key={order._id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {order.Order_id}
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center">
                      <StatusIcon className={`w-4 h-4 mr-2 ${statusInfo.color.split(' ')[0]}`} />
                      <span className={`px-3 py-1 text-sm rounded-full ${statusInfo.color}`}>
                        {statusInfo.label}
                      </span>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <span className={`px-3 py-1 text-sm rounded-full ${
                      order.Payment_Status === "Paid" 
                        ? "text-green-600 bg-green-100"
                        : "text-yellow-600 bg-yellow-100"
                    }`}>
                      {order.Payment_Status}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {order.Billing_Name}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {order.City}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
      </div>
      
    </div>
  );
}